@import "../../theme/themeVariables.scss";

.toggleButtonContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.toggleButton {
  cursor: pointer;
  user-select: none;
  background-color: $secondary;
  color: $primary;
  display: inline-block;
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  padding-left: 0.4vw;
  padding-right: 0.6vw;
  padding-top: 0.2vh;
  padding-bottom: 0.2vh;
  max-width: 200px;
  width: 80px;
  text-align: center;
  font-size: 1.1em;
  font-weight: 500;
  margin-bottom: 0.5vh;
  border: 2px solid $primary;
  border-radius: 0px 12px 12px 0px;
  z-index: 5;

  &.right {
    border-radius: 12px 0px 0px 12px;
    width: 115px;
  }
}

.toggleButton:hover {
  background-color: $complimentaryLowOp;
}

// .toggleButtonRight {
//   cursor: pointer;
//   user-select: none;
//   background-color: $secondary;
//   color: $primary;
//   display: inline-block;
//   box-sizing: border-box;
//   -webkit-box-sizing: border-box;
//   -moz-box-sizing: border-box;
//   padding-left: 1.2vw;
//   padding-right: 0.8vw;
//   padding-top: 0.5vh;
//   padding-bottom: 0.5vh;
//   max-width: 200px;
//   font-size: 1.3em;
//   font-weight: 500;
//   margin-bottom: 0.5vh;
//   border: 2px solid $primary;
//   border-radius: 25px 0px 0px 25px;
// }

// .toggleButtonRight:hover {
//   background-color: $complimentaryLowOp;
// }

.toggleModal {
  border: 1px solid black;
  border-radius: 5px;
  padding-top: 0.8vh;
  padding-bottom: 0.8vh;
  margin-bottom: 0.7vh;
  width: 100%;
  background-color: rgb(248, 248, 248);
  z-index: 500;
  font-size: 0.9em;

  &.fitCont {
    width: fit-content;
  }

  &.noBgNoBorder {
    // background-color: rgba(248, 248, 248, 0.43);
    // border: 1px solid rgb(201, 201, 201);
    border: 0;
    background-color: rgba(255, 255, 255, 0);
  }

  &.smallZ {
    z-index: 0;
  }
}

.slide-out-component {
  position: fixed;
  top: calc(50% - 15px);
  left: -30px;
  width: 30px;
  height: 70px;
  background-color: rgb(255, 255, 255);
  display: flex;
  justify-content: center;
  align-items: center;
  transition: left 0.3s ease-in-out;
  cursor: pointer;
  z-index: 100;
  border: 2px solid $primary;
  border-left: 0;
  background-color: $secondary;
  border-radius: 0px 15px 15px 0px;
}

.slide-out-component.visible {
  left: 0;
}

.separator {
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;

  hr {
    position: absolute;
    width: 100%;
  }

  .text {
    z-index: 2;
    background-color: white;
    padding-left: 5px;
    padding-right: 5px;
  }
}

.footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 9vh;
  min-height: 90px;

  display: flex;
  justify-content: center;
  align-items: center;

  background-color: $primary;
  color: $lightText;

  .footerData {
    .foorterList {
      font-size: 1.1em;
      display: flex;

      .section {
        display: flex;
        flex-direction: column;
        margin-left: calc(5px + 1vw);
        margin-right: calc(5px + 1vw);
      }

      .footerListItem {
        cursor: pointer;
        user-select: none;
        margin-bottom: 2px;

        a {
          color: $lightText;
          text-decoration: none;

          &:hover {
            color: $secondary;
          }
        }
      }
    }
  }
}

.soonTooltip {
  background-color: rgba(0, 0, 0, 0.748);
  font-size: 1.2em;
  padding: 10px 15px;
  border-radius: 5px;
  margin-bottom: -20px;
}

.autofitWindow {
  .modal-content {
    position: absolute;
    width: 90%;
    height: 90%;
    transition: transform 0.5s ease-in-out;
  }

  .slide-in-right {
    transform: translateX(0);
  }

  .slide-out-left {
    transform: translateX(-110%);
  }

  .backSection {
    display: flex;
    cursor: pointer;

    .backIcon {
      width: 18px;
    }
  }
}

.confirmModal {
  height: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-direction: column;

  .confirmAsk {
    font-size: 1.1em;
    text-align: center;
  }

  .confirmButtons {
    display: flex;
    width: 100%;
    justify-content: space-around;
    margin-top: 15px;

    .button {
      padding: 3px 15px 3px 15px;
      border: 1px solid black;
      border-radius: 7px;
      width: 60px;
      text-align: center;
      cursor: pointer;
      background-color: $primary;
      color: white;

      &:hover {
        background-color: $complimentary;
        scale: 103%;
        text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.3);
      }
    }
  }
}

.broadcastModal {
  display: flex;
  flex-direction: column;

  .broadcastHeader {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .title {
      font-size: 1.4em;
      font-weight: 600;
      text-decoration: underline;
    }

    .icon {
      scale: 130%;
      cursor: pointer;

      &:hover {
        scale: 140%;
      }
    }
  }

  .message {
    margin-top: 30px;
    font-size: 1.1em;
  }
}

.blockingOverlay {
  position: absolute;
  height: 100%;
  width: 100%;
  z-index: 9999;
  top: 0;
  left: 0;
}

.closeButton {
  // position: absolute;
  // top: 5px;
  // right: 5px;
  display: flex;
  // margin-right: 10px;
  // margin-left: 5px;
  // background-color: rgb(151, 13, 13);
  background-color: #b22222;
  border-radius: 6px;
  border: 1px solid rgb(182, 182, 182);
  cursor: pointer;
  // z-index: 1;

  .closeIcon {
    color: white;
    height: 22px;
    margin-left: 7px;
    margin-right: 7px;
  }
}

.customButton {
  &.colored {
    background-color: $primary;
  }

  // Custom css tailored for different button requirements:

  &.fileLoaderButton {
    width: 22.2ch;
    font-size: 0.8em;
    letter-spacing: 0.5px;
    margin-top: 5px;
    margin-bottom: 5px;
    margin-left: 0.4vw;
    margin-right: 0.4vw;
  }

  &.modelLoaderButton {
    width: 19.8ch;
    font-size: 0.8em;
    letter-spacing: 0.5px;
  }

  &.noMargin {
    margin: 0;
  }

  &.dataManagerButton {
    margin-right: 15px;
  }

  &.whiteAppBarButton {
    background-color: white;
    border-color: $primary;
    border-style: solid;
    border-width: 2px;
    color: $primary;
    font-size: 0.8em;
    margin-left: 10px;

    &:hover {
      color: white;
      background-color: $primary;
    }
  }

  &.demoOpenButton {
    background-color: $primary;
    border-color: $primary;
    border-style: solid;
    border-width: 2px;
    color: white;
    font-size: 0.8em;
    margin-left: 10px;

    &:hover {
      color: $primary;
      background-color: white;
    }
  }

  &.clearVariant {
    background-color: white;
  }

  &.warningClear {
    position: absolute;
    top: 0;
    left: 0;
    margin-left: 0.8vw;
    margin-top: 0.5vh;
    color: white;
    background-color: $warn;

    &:hover {
      color: $warn;
      background-color: white;
      border: 1px solid $warn;
    }
  }

  &.graphHeaderVersion {
    padding: 0;
    font-size: 0.75em;
  }

  &.fitVersion {
    padding: 5px 30px 5px 30px;
    font-size: 0.8em;
    letter-spacing: 0.5px;
  }

  &.addChi2InOverlay {
    padding: 3px 20px 3px 20px;
  }

  &.loopExtrasVariant {
    margin-bottom: 10px;
    font-size: 0.85em;
    padding: 4px 6px;
    width: 220px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &.largeStart {
    font-size: 1.2em;
    letter-spacing: 2px;
    padding: 5px 35px;
  }

  &.smallLoopChipVersion {
    padding: 2px 5px;
    font-size: 0.75em;
    letter-spacing: 1px;
  }

  &.demoVersion{
    min-width: 67px;
  }
}
