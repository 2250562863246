.passwordResetPage {
  display: flex;
  flex-direction: column;
  height: 100vh;
  width: 100%;
  justify-content: center;
  align-items: center;
  .entryArea {
    max-width: 300px;
    display: flex;
    flex-direction: column;

    .missmatch {
      font-size: 1.1em;
      margin-top: calc(5px + 0.2vh);
      // margin-bottom: calc(5px + 1vh);
      margin-left: calc(5px + 0.5vw);
      color: red;
    }
    .success {
      font-size: 1.1em;
      margin-top: calc(5px + 0.2vh);
      // margin-bottom: calc(5px + 1vh);
      margin-left: calc(5px + 0.5vw);
      color: green;
    }

    h2{
      margin-bottom: 0;
    }

    .resetButton{
      margin-top: 15px;
    }
  }
}
