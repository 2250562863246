@import "../../../theme/themeVariables.scss";

.answerArea {
  margin-top: 10px;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  .answerEntry {
    width: calc(50vw - 25px);

    &.withDelete {
      width: calc(50vw - 52px);
    }
  }
  .deleteAnswer {
    height: 22px;
    margin-left: 5px;
    cursor: pointer;
  }
}

.pollSolver {
  width: 90%;
  border: 1px solid black;
  padding: 10px;
  border-radius: 10px;

  .question {
    font-size: 1.2em;
    padding-bottom: 10px;
    border-bottom: 1px solid black;
  }

  .solveButtonArea {
    margin-top: 20px;
    width: 100%;
    display: flex;
    justify-content: center;
  }

  .dateArea {
    display: flex;
    justify-content: flex-end;
    margin-top: 10px;
    .title {
      margin-right: 10px;
    }
  }
}

.answerBar {
  position: relative;
  width: 100%;
  display: flex;
  justify-content: space-between;
  border: 1px solid black;
  margin-top: 5px;
  margin-bottom: 5px;

  .filledPart {
    position: absolute;
    height: 100%;
    left: 0;
    background-color: $selectedLine;
  }

  .emptyPart {
    position: absolute;
    height: 100%;
    right: 0;
  }

  .answer {
    z-index: 5;
    padding-left: 3px;
  }

  .percentage {
    z-index: 5;
    padding-right: 3px;
  }
}

.pollWithAnswers {
  display: flex;
  flex-direction: column;
  width: 100%;
  border: 1px solid gray;
  border-radius: 5px;
  padding: 7px;
  margin-top: 10px;
  margin-bottom: 10px;

  &:hover {
    .pollHeader {
      .controlIcons {
        display: flex;
        align-items: center;
      }
    }
  }

  .pollHeader {
    display: flex;
    justify-content: space-between;
    cursor: pointer;
    user-select: none;

    .questionExpand {
      display: flex;
      align-items: center;
      width: 100%;
      .question {
        font-size: 1.2em;
      }
    }

    .controlIcons {
      display: none;
    }
  }

  .expandableArea {
    .times {
      margin-top: 5px;
      display: flex;
      justify-content: space-between;
    }
  }
}

.pollEditModal {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 100%;

  .questionWithAnswers {
    .title {
      font-size: 1.2em;
      width: 100%;
      margin-bottom: 10px;
    }

    .editQuestionEntry {
      width: 100%;
      margin-bottom: 25px;
    }

    .addAnswerArea {
      display: flex;
      .addAnswerButton {
        padding: 5px;
        cursor: pointer;
        display: flex;
        align-items: center;
        align-content: center;
        color: green;

        &:hover {
          scale: 101%;
          text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.233);
        }

        .addText {
          margin-left: 5px;
          font-size: 1.1em;
        }
      }
    }
  }

  .durationArea {
    display: flex;
    align-items: center;
    justify-content: space-around;
    width: 100%;
    margin-top: 5px;
    padding-top: 10px;
    padding-bottom: 10px;
    border-top: 1px solid black;

    .duration {
      .title {
        font-size: 1.2em;
        font-weight: 500;
      }

      .time {
        display: flex;
        align-items: center;
        flex-direction: column;

        .timeSelector {
          margin-top: 15px;
        }
      }
    }
  }
}

div[class*="MuiPickersPopper-root"] {
  z-index: 9000; /* Ensure this is higher than your modal's z-index */
}
