.custom-gauge-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 5px;
}

.gauge-name {
  margin-bottom: -15px;
  font-size: 1.2em;
  padding-bottom: 0;
  font-weight: 600;
}

.gauge-controls {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: 0;

  input {
    margin-left: 5px;
    width: 50px;
  }
}
