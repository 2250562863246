@import "../../theme/themeVariables.scss";

.adminSystemPage {
  display: flex;
  height: 100vh;

  .sidePannel {
    flex: 1;
    min-width: 150px;
    height: 100%;
    border-right: 2px solid $primary;
    background-color: $secondary;
    margin-right: 5px;

    .userArea {
      height: 12vh;
      // border: 1px solid pink;
      padding-top: 10px;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      background-color: $complimentary;
      color: $lightText;

      .userName {
        font-size: 1.1em;
        margin: 5px;
        word-break: break-all;
      }
    }

    .pagesArea {
      height: 82vh;

      .linkContainer {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: flex-start;
        flex-direction: column;

        .link {
          margin-left: 20%;
          font-size: 1.2em;
          font-weight: 600;
          margin-top: calc(5px + 0.2vh);
          margin-bottom: calc(5px + 0.2vh);
          user-select: none;
          cursor: pointer;

          &:hover {
            color: $complimentary;
            scale: 103%;
            text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.3);
          }
        }
      }
    }

    .sidePannelBottom {
      height: 5vh;
      display: flex;
      justify-content: center;
      align-items: center;

      .logout {
        user-select: none;
        cursor: pointer;

        .logoutButton {
          &:hover {
            scale: 102%;
            box-shadow: 3px 3px 5px rgba(0, 0, 0, 0.3);
          }
        }
      }
    }
  }

  .adminSystemContent {
    flex: 9;
    height: 100vh;
    overflow: auto;
  }
}

.profilePage {
  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;

  .profileHomeContainer {
    margin-top: calc(15px + 1vh);
    width: 100%;
    display: flex;

    .pollArea {
      flex: 1;

      .pollsTitle {
        font-size: 1.4em;
        margin-top: 10px;
        margin-bottom: 15px;
      }

      .pollCounter {
        font-size: 1.1em;
        margin-bottom: 15px;
      }
    }

    .packagesSection {
      flex: 2;
      display: flex;
      flex-direction: column;
      align-items: center;

      .packageSecTitle {
        font-size: 1.5em;
        width: 70%;
        margin-bottom: calc(10px + 0.5vh);
      }
      .packageArea {
        margin-top: calc(5px + 0.5vh);
        width: 70%;

        .activePacks {
          &.last {
            margin-top: calc(5px + 1vh);
          }
        }

        .areaTitle {
          font-size: 1.2em;
          margin-bottom: calc(5px + 1vh);
        }
      }
    }
  }

  .redirectButtons {
    margin-top: calc(15px + 2vh);
    width: 90%;
    display: flex;
    justify-content: flex-end;
  }
}

.faqPage {
  height: 100vh;
  padding-top: calc(5px + 1vh);
  padding-left: calc(25px + 1vw);
  padding-right: calc(25px + 1vw);
}

.detailsPage {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  height: 100vh;

  .userDetailsContainer {
    flex: 9;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .updateButton {
      margin-top: 25px;
      width: fit-content;

      &:hover {
        scale: 102%;
        box-shadow: 3px 3px 5px rgba(0, 0, 0, 0.3);
      }
    }
  }

  .deleteDeact {
    flex: 1;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    .delDeactButton {
      background-color: red;
      margin: 10px;

      &:hover {
        scale: 102%;
        box-shadow: 3px 3px 5px rgba(0, 0, 0, 0.3);
        filter: grayscale(0.2);
      }
    }
  }
}

.adminPage {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding-top: calc(25px + 1vh);
  padding-left: calc(10px + 1vw);
  padding-right: calc(10px + 1vw);
  height: 100vh;

  .pollsTabArea {
    width: 90%;
  }
  .extrasTabArea {
    width: 90%;
  }

  .pollingSection {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
    // max-width: 1040px;

    .divider {
      margin-top: 25px;
      margin-bottom: 25px;
      width: 100%;
    }

    .pollingTitle {
      font-size: 1.3em;
      letter-spacing: 2px;
      font-weight: 600;
      margin-bottom: calc(10px + 0.5vh);
    }

    .newPoll {
      border: 1px solid black;
      padding: 10px;
      border-radius: 7px;
      padding-bottom: 20px;

      .pollTitle {
        font-size: 1.2em;
        font-weight: 500;
        margin-bottom: 5px;
      }

      .newQuestionEntry {
        width: 50vw;
        min-width: 350px;
      }

      .answers {
        margin-left: 25px;
      }

      .addAnswerArea {
        display: flex;
        .addAnswerButton {
          padding: 5px;
          cursor: pointer;
          display: flex;
          align-items: center;
          align-content: center;
          color: green;

          &:hover {
            scale: 101%;
            text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.233);
          }

          .addText {
            margin-left: 5px;
            font-size: 1.1em;
          }
        }
      }

      .createPollButtonArea {
        width: 100%;
        margin-top: 15px;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .durationArea {
        display: flex;
        align-items: center;
        justify-content: space-around;
        width: 100%;
        margin-top: 15px;
        padding-top: 15px;
        padding-bottom: 15px;
        border-top: 1px solid black;

        .duration {
          .title {
            font-size: 1.2em;
            font-weight: 500;
          }

          .time {
            display: flex;
            align-items: center;
            flex-direction: column;

            .timeSelector {
              margin-top: 15px;
            }
          }
        }
      }
    }

    .activePollArea {
      .noActive {
        font-size: 1.1em;
      }

      .polls {
        width: 45vw;
        min-width: 250px;
        max-width: 900px;
      }
    }
  }

  .extrasArea {
    width: 100%;

    .globalMessage {
      display: flex;
      flex-direction: column;
      .title {
        font-size: 1.5em;
      }

      textarea {
        font-size: 1em;
        width: 40vw;
        max-width: 75vw;
        min-width: 300px;
        height: 20vh;
        max-height: 80vh;
        min-height: 250px;
        padding: 8px 12px;
        border-radius: 8px;
        border: 1px solid grey;
        box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.1);

        &:hover {
          border-color: blue;
        }

        &:focus {
          outline: none;
          border-color: blue;
          box-shadow: 0 0 0 3px lightblue;
        }
      }

      .buttonContainer {
        margin-top: 20px;
      }
    }
  }

  .usersTabArea {
    max-width: 90%;
  }

  .usersTableSection {
    // width: 90%;
    // max-width: 1040px;

    .usersTableSearchArea {
      width: 100%;
      display: flex;
      margin-bottom: 5px;

      .usersTableSearch {
        width: 100%;
      }

      .filter {
        height: 50px;
        width: 50px;
        padding: 10px;
        cursor: pointer;
      }
    }

    .usersTableTitle {
      font-size: 1.3em;
      letter-spacing: 2px;
      font-weight: 600;
      margin-bottom: calc(10px + 0.5vh);
    }

    .usersTableContainer {
      width: 100%;
      height: 80%;
    }
  }
}

.welcomePage {
  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .message {
    font-size: 2.2em;
    margin-bottom: 15px;
  }

  .messageSmall {
    margin-bottom: calc(25px + 2vh);
    font-size: 1.3em;
  }

  .loginButton {
    width: 100px;
  }
}

.contactPage {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .themeArea {
    margin-bottom: 20px;

    .subjectInput {
      width: 50vw;
      min-width: 300px;
    }
  }

  .messageTitle {
    font-size: 1.5em;
    letter-spacing: 1px;
    margin-bottom: 10px;
  }

  .textInput {
    textarea {
      font-size: 1em;
      width: 50vw;
      min-width: 300px;
      height: 50vh;
      min-height: 250px;
      padding: 8px 12px; // You can adjust padding as per your needs
      border-radius: 8px;
      border: 1px solid grey; // You can adjust border color as per your needs
      box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.1); // Optional shadow for a subtle 3D effect

      &:hover {
        border-color: blue; // You can adjust hover border color as per your needs
      }

      &:focus {
        outline: none;
        border-color: blue; // Adjust focus border color if needed
        box-shadow: 0 0 0 3px lightblue; // Adjust focus box shadow color if needed
      }

      // To remove the resize handle on the bottom right in some browsers
      resize: none;
    }
  }

  .fileInputArea {
    width: 50vw;
    display: flex;
    flex-direction: column;
  }

  .buttonArea {
    margin-top: 20px;

    .messageButton {
      font-size: 1em;
    }
  }
}
