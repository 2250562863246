@import "../../../theme/themeVariables.scss";

$borderRadiusVal: 5px;

.loopWindow {
  position: absolute;
  border: 1px solid rgb(235, 235, 235);
  border-radius: $borderRadiusVal;
  background-color: rgba(255, 255, 255, 0.445);
  padding: 15px;
  @include even-box-shadow(10px, 5px, rgba(0, 0, 0, 0.02));
  width: 92%;
  height: 96%;
  left: 0;
  top: 0;
  margin-left: 4%;
  margin-right: 4%;
  user-select: none;
  display: flex;
  flex-direction: column;

  .disclaimer{
    flex: 1;
    font-style: italic;
    font-size: 1.1em;
    color: gray;
  }

  &.empty {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .noTerms {
    flex: 24;
    display: flex;
    justify-content: center;
    align-items: center;

    .text{
      font-size: 1.5em;
      margin-top: -5vh;
    }
  }

  .contentContainer{
    flex: 24;
    .submitButtons {
      display: flex;
      width: 100%;
      justify-content: center;
  
      // .submitButton {
      //   font-size: 1.2em;
      //   letter-spacing: 2px;
      //   padding: 5px 35px;
      // }
    }
  
    .loopOptions {
      display: flex;
      height: 95%;
  
      .loopInput {
        // border: 1px solid black;
        overflow: auto;
        height: 95%;
        padding-right: 7px;
        padding-left: 7px;
        padding-top: 7px;
  
        flex: 3;
      }
  
      .loopOutputs {
        height: 500px;
        // border: 1px solid black;
  
        flex: 1.5;
  
        .buttonsSection {
          display: flex;
          flex-direction: column;
          align-items: center;
  
          .titleContainer {
            margin-top: 20px;
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
  
            &:first-child {
              margin-top: 0;
            }
  
            .title {
              width: 220px;
              font-size: 1.25em;
              letter-spacing: 0.5px;
            }
          }
  
          .trackingArea {
            display: flex;
            width: 100%;
            flex-direction: column;
            align-items: center;
  
            margin-top: 15px;
          }
  
          .autosaveArea {
            margin-top: 15px;
            display: flex;
            width: 100%;
            flex-direction: column;
            align-items: center;
          }
  
          // .selectionButton {
          //   margin-bottom: 10px;
          //   font-size: 0.85em;
          //   padding: 4px 6px;
          //   width: 220px;
          // }
  
          // .selectionButton:last-child {
          //   margin-bottom: 0;
          // }
  
          .trackedParameters {
            display: flex;
            justify-content: center;
            width: 100%;
  
            .allTrackedParams {
              min-width: 220px;
  
              .modelSet {
                margin-top: 5px;
                margin-bottom: 5px;
                padding-bottom: 5px;
                // border-bottom: 1px solid #87929b31;
                display: flex;
                flex-direction: column;
  
                // &:last-child{
                //   border-bottom: 0;
                // }
  
                .title {
                  font-size: 1.15em;
                  letter-spacing: 1px;
                }
  
                .parameters {
                  display: flex;
                  flex-direction: column;
  
                  .trackedParameter {
                    display: flex;
                    margin-left: 10px;
                    .content {
                      display: flex;
  
                      .row {
                        margin-left: 10px;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

.chi2Term {
  @include even-box-shadow(7px, 3px, rgba(0, 0, 0, 0.05));
  margin-bottom: 15px;
  padding: 10px;
  border-radius: $borderRadiusVal;

  display: flex;
  width: 100%;

  &:hover {
    @include even-box-shadow(7px, 3px, rgba(0, 0, 0, 0.1));
  }

  .dataFiles {
    flex: 1.5;
    padding-right: 5px;
    width: 100%;

    .chiFileName {
      font-size: 1.2em;
      letter-spacing: 0.5px;
      margin-bottom: 5px;
    }

    // .datafileLoadButton {
    //   padding: 2px 5px;
    //   font-size: 0.75em;
    //   letter-spacing: 1px;
    // }

    .selectedFilesList {
      margin-left: 10px;
      width: fit-content;
      max-height: 130px;
      overflow: auto;

      .selectedFile {
        margin-top: 2px;
        padding-bottom: 2px;
        margin-bottom: 2px;
        border-bottom: 1px solid #87929b4f;
      }

      .selectedFile:first-child {
        margin-top: 0;
      }
      .selectedFile:last-child {
        padding-bottom: 0;
        margin-bottom: 0;
        border-bottom: 0;
      }
    }
  }

  .models {
    flex: 2;
    padding-left: 5px;
    padding-right: 5px;

    .modelsHeader {
      text-align: right;
      font-size: 1.1em;
      cursor: help;

      .helpIcon {
        height: 18px;
      }
    }

    .modelEntry {
      display: flex;
      justify-content: space-between;
      margin-top: 5px;
      margin-bottom: 5px;

      .buttonsArea {
        display: flex;

        .button {
          padding: 2px 8px;
          cursor: pointer;
          background-color: white;

          &.selected {
            background-color: $primary;
            font-weight: 500;
            color: white;
          }

          &:first-child {
            border-radius: $borderRadiusVal 0px 0px $borderRadiusVal;
          }

          &:last-child {
            border-radius: 0px $borderRadiusVal $borderRadiusVal 0px;
          }
        }
      }
    }
  }

  .infoPanel {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    padding-left: 5px;

    .container {
      padding-right: 15px;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      .infoEntry {
        display: flex;
        align-items: flex-start;

        &.column {
          flex-direction: column;
        }

        .title {
          font-size: 1em;
        }

        .value {
          margin-left: 10px;
        }
      }
    }
  }
}

.modelOptionsTooltip {
  background-color: white;
  color: black;
  padding: 10px;
  font-size: 1.2em;
  border-radius: 5px;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.3);
}

.chi2Term:last-child {
  margin-bottom: 0;
}

.fileSelectionModal {
  padding: 10px;

  .selectToggleButton {
    margin-bottom: 10px;
  }

  .fileList {
    list-style: none;
    padding: 0;

    .fileItem {
      padding: 5px;
      cursor: pointer;
      border: 1px solid #ccc;
      margin: 2px 0;

      &:hover {
        background-color: #f0f0f0;
      }

      &.selected {
        background-color: #d0ebff;
        border-color: #4a90e2;
      }
    }
  }
}

.parameterSelectorWindow {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.modelSaveSelectModal {
  .fileNamePrefix {
    width: 100%;
    margin-bottom: 10px;
  }

  .list {
    max-height: 200px;
    overflow: auto;
  }
}

.curveSaveSelectModal {
  .fileNamePrefix {
    margin-bottom: 10px;
    width: 100%;
  }

  .list {
    max-height: 200px;

    .curveEntry {
      .checkboxLabelContainer {
        display: flex;

        .modelName {
          margin-right: 5px;
        }

        .quantityName {
          margin-left: 5px;
        }
      }
    }
  }

  .noCurves {
    display: flex;
    flex-direction: column;

    .mainTitle {
      font-size: 1.2em;
      font-weight: 500;
    }

    .subText {
      margin-top: 10px;
      font-style: italic;
      color: gray;
    }
  }
}

.parameterSelector {
  display: flex;
  flex-direction: column;
  min-width: 100px;
  width: fit-content;
  min-height: 100px;
  border-radius: $borderRadiusVal;
  // margin-top: 15px;
  // margin-bottom: 15px;
  margin: 15px;
  padding: 10px;
  user-select: none;

  &:first-child {
    margin-top: 0;
  }

  &:last-child {
    margin-bottom: 0;
  }

  @include even-box-shadow(7px, 3px, rgba(0, 0, 0, 0.05));
  &:hover {
    @include even-box-shadow(7px, 3px, rgba(0, 0, 0, 0.1));
  }

  .header {
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    font-size: 1.1em;
    padding-bottom: 1px;
  }

  .content {
    table {
      border-spacing: 0;
      border-left: 1px solid rgba(0, 0, 0, 0.1);
      margin-top: calc(10px + 0.5vh);

      th {
        margin: 0;
        border-top: 1px solid rgba(0, 0, 0, 0.1);
        padding-right: 3px;
        padding-left: 3px;
        width: auto;
        border-bottom: 1px solid rgba(0, 0, 0, 0.1);
        border-right: 1px solid rgba(0, 0, 0, 0.1);
        text-align: center;
      }

      td {
        cursor: pointer;
        margin: 0;
        padding: 0;
        width: auto;
        border-bottom: 1px solid rgba(0, 0, 0, 0.1);
        border-right: 1px solid rgba(0, 0, 0, 0.1);
        text-align: center;
        font-size: 1rem;
        padding: 2px 7px;

        &.fixed {
          font-weight: 600;
        }

        &:hover {
          @include even-box-shadow(7px, 3px, rgba(0, 0, 0, 0.08));
        }

        &.selected {
          background-color: $selectedLine;
        }

        &.disabled {
          color: gray;
          background-color: lightgray;
          &:hover {
            @include even-box-shadow(7px, 3px, rgba(0, 0, 0, 0));
          }
        }
      }
    }
  }
}

.loopStopWindow {
  position: absolute;
  width: 450px;
  height: 100px;
  bottom: 10%;
  right: 40%;
  left: 40%;
  padding: 7px;
  border: 1px solid rgb(179, 179, 179);
  border-radius: 10px;
  box-shadow: 1px 1px 5px rgb(201, 201, 201);
  background-color: white;
  display: flex;
  justify-content: space-between;

  align-items: center;
  z-index: 9999;

  .loopInfo {
    .text {
      font-size: 1.35em;
      font-weight: 600;
      letter-spacing: 0.5px;
    }
    .iterationCount {
      font-size: 1.25em;
    }
  }

  .buttonArea {
    .stopLoopButton {
      font-size: 1.25em;
    }
  }

  .pausedOptions {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    height: 100%;
    padding: 7px;

    .header {
      display: flex;
      justify-content: space-between;

      .title {
        font-size: 1.4;
        font-weight: 600;
        letter-spacing: 0.5px;
      }
      .iteration {
        font-size: 1.1;
      }
    }

    .pausedBody {
      display: flex;
      justify-content: space-around;
    }
  }
}
