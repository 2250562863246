@import "../../theme/themeVariables.scss";

.models {
  width: 100%;

  .modelsButtonSection {
    display: flex;
    width: 100%;
    justify-content: space-evenly;

    // .modelsButton {
    //   background-color: $primary;
    //   width: 19.8ch;
    //   font-size: 0.8em;
    //   letter-spacing: 0.5px;
    // }
  }

  .demoOnlyWarning {
    width: 90%;
    max-width: 200px;
    margin-left: 10px;
    margin-bottom: 10px;
    font-size: 1em;
  }

  .modelEntry {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    font-size: 1.05em;
    user-select: none;

    .modelEntryName {
      margin-right: 5px;

      &.beingLinked {
        color: gray;
      }
    }

    .modelEntryIcon {
      display: none;
      margin-right: 5px;

      &:last-child {
        margin-right: 0;
      }
    }
  }

  .modelEntry:hover {
    .modelEntryIcon {
      display: inline;
      cursor: pointer;
      height: 15px;
      margin-left: 0.2vw;
    }
  }

  .nestedModels {
    margin-left: 0.5vw;
    font-weight: 600;
  }
}

.modalList {
  .modalListEntry {
    border-bottom: 1px solid #79797979;
  }

  .modalListEntry:last-child {
    border-bottom: 0px solid #797979a4;
  }

  .modalListEntryName {
    cursor: pointer;
    display: inline;
    font-size: 1.1em;
    margin-top: 0.5vh;
    margin-bottom: 0.5vh;
  }

  .modalListEntryName:hover {
    color: $primary;
    text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.3);
  }
}

.fileUpload {
  margin-left: 0.3vw;
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */

  .file-list {
    list-style-type: none;
    padding: 0;
    margin-top: 0;
    margin-bottom: 0;
  }

  .file-list li {
    cursor: pointer;
    padding: 5px;
    height: auto;
    min-height: 26px;
    line-height: 1;
  }

  .file-list li.selected {
    background-color: $selectedLine;
  }

  .fileName {
    display: inline;
    word-break: break-all;
  }

  .deleteFileIcon {
    display: none;
    vertical-align: middle;
  }

  .file-list li:hover .deleteFileIcon {
    display: inline;
    cursor: pointer;
    height: 12px;
    margin-left: 0.4vw;
    margin-bottom: 2px;
  }

  .downloadFileIcon {
    display: none;
    vertical-align: middle;
  }

  .file-list li:hover .downloadFileIcon {
    display: inline;
    cursor: pointer;
    height: 14px;
    margin-left: 0.4vw;
    margin-bottom: 2px;
  }

  .uploadHeader {
    display: flex;
    justify-content: space-between;
    .selectionTitle {
      width: 100%;
      margin-left: 15px;
      text-align: center;
      margin-bottom: 0.3vh;
      font-size: 1.1em;
      font-weight: 500;
    }

    .icon {
      margin-right: 5px;
      cursor: pointer;
    }
  }

  .uploadButtons {
    display: flex;
    justify-content: space-evenly;
    margin-bottom: 1vh;
    flex-wrap: wrap;

    // .fileUploadButton {
    //   background-color: $primary;
    //   width: 22.2ch;
    //   font-size: 0.8em;
    //   letter-spacing: 0.5px;
    // }
    // .fileUploadButton {
    //   width: 22.2ch;
    //   font-size: 0.8em;
    //   letter-spacing: 0.5px;
    // }
  }

  // .fileUploadButton {
  //   background-color: $primary;
  //   width: 10ch;
  //   margin-top: 5px;
  //   margin-bottom: 5px;
  //   margin-left: 0.4vw;
  //   margin-right: 0.4vw;
  //   font-size: 0.8em;
  //   letter-spacing: 0.5px;
  // }
}
