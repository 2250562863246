/* Styling for parameter status representation */
.param-free {
  color: black;
  font-weight: normal;
}

.param-warning {
  color: orange;
  font-weight: normal;
}

.param-fixed {
  font-weight: bold;
  color: black;
}

.param-grouped {
  background-color: #d4e157;
  padding: 5px;
  border-radius: 5px;
}

/* Table styling */
.color-code-table {
  width: 100%;
  border-collapse: collapse;
  margin: 20px 0;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.color-code-table th,
.color-code-table td {
  padding: 12px 15px;
  text-align: left;
  border: 1px solid #ddd;
}

.color-code-table th {
  background-color: #f5f5f5;
  font-weight: bold;
}

.color-code-table tr:nth-child(even) {
  background-color: #f9f9f9;
}

/* Example group colors */
.group-1 {
  background-color: #e3f2fd;
}
.group-2 {
  background-color: #f3e5f5;
}
.group-3 {
  background-color: #e8f5e9;
}
.group-4 {
  background-color: #fff3e0;
}
.group-5 {
  background-color: #f3e5f5;
}
.group-6 {
  background-color: #e0f7fa;
}
.group-7 {
  background-color: #fbe9e7;
}
.group-8 {
  background-color: #f1f8e9;
}
.group-9 {
  background-color: #ede7f6;
}
.group-10 {
  background-color: #e0f2f1;
}
