@import "../theme/themeVariables.scss";

.dashboardContainer {
  height: 30vh;
  display: flex;

  .leftSideContainer {
    height: 100%;
    flex: 1;
    border: 1px solid black;
  }
  .middleContainer {
    height: 100%;
    flex: 4;
    border: 1px solid black;
  }
  .rightSideContainer {
    height: 100%;
    flex: 1;
    border: 1px solid black;
  }
}

.mainContainer {
  margin-top: 110px;
  height: 88%;
}

.gridContainer {
  display: flex;
  justify-content: space-between;
  height: 100%;
}

.copyright {
  position: absolute;
  bottom: 0;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 0;
}

.stickyFileWindow {
  position: absolute;
  bottom: 0;
  left: 0;
  height: 305px;
  width: 240px;
  border: 1px solid black;
  border-top-right-radius: 15px;
  margin-left: 10px;
  margin-bottom: 10px;
  background-color: white;
  box-shadow: -6px 5px 10px rgba(0, 0, 0, 0.3);
  display: flex;
  flex-direction: column;
  z-index: 5000;
  user-select: none;

  $leftmargin: 5px;
  $inputHeight: 30px;

  .nPoints {
    display: flex;
    padding-left: $leftmargin;
    align-items: center;
    margin-top: 5px;
    margin-bottom: 5px;

    .points {
      padding-left: $leftmargin;
      width: 100px;
    }
  }

  hr {
    margin-top: 0;
    margin-bottom: 5px;
    width: 100%;
    background-color: $primary;
    height: 1px;
    border: none;
  }

  .header {
    padding-left: $leftmargin;
    font-size: 1.1em;
    font-weight: 600;
    padding-top: 4px;
    padding-bottom: 7px;
    background-color: $secondary;
    color: $primary;
    border-top-right-radius: 15px;

    .fileName {
      width: fit-content;
      cursor: pointer;

      &:hover {
        text-decoration: underline;
      }
    }

    .closeButtonContainer{
      position: absolute;
      top: 0;
      right: 0;
      margin-right: 0.5vw;
      margin-top: 0.5vh;
    }

    // .closeIcon {
    //   cursor: pointer;
    //   max-width: 15px;
    //   position: absolute;
    //   top: 0;
    //   right: 0;
    //   margin-right: 0.5vw;
    //   margin-top: 0.5vh;
    //   opacity: 0.9;
    //   filter: grayscale(0.5);
    // }
  }

  .cutSettingsTitle {
    padding-left: $leftmargin;
    padding-top: 4px;
    padding-bottom: 4px;
  }

  .infoIcon {
    height: 20px;
  }

  .unitsContainer {
    margin-left: $leftmargin;
    display: flex;
    align-items: center;

    .xUnitSelectContainer {
      display: inline;
    }

    .unitSelectorLabel {
      display: flex;
      align-items: center;
    }

    .x_unit_select {
      width: 100%;
      height: $inputHeight;
      padding-top: 0;
    }
  }

  .allCutsContainer {
    position: relative;
    height: 175px;
    overflow: auto;
    .cutContainer {
      display: flex;
      align-items: center;
      .deleteCut {
        height: 16px;
        margin-bottom: -5px;
        cursor: pointer;
        display: none;
      }
    }
    .cutContainer:hover {
      .deleteCut {
        display: inline;
      }
    }

    .addCut {
      position: absolute;
      height: 18px;
      right: 30px;
    }
  }

  .rangeContainer {
    margin-left: $leftmargin;
    margin-top: 5px;
    display: inline;
    width: 80%;

    .rangeValContainer {
      margin-top: 3px;
      display: flex;
      justify-content: space-evenly;
      margin-right: 0;
    }

    .rangeVal {
      height: $inputHeight;
      width: 85px;
    }

    input[type="number"]::-webkit-inner-spin-button,
    input[type="number"]::-webkit-outer-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    input[type="number"] {
      appearance: textfield;
      -moz-appearance: textfield;
    }
  }

  .zeroCutCheckbox {
    margin-left: 0;
  }
}
