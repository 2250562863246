/* === General Image Styles === */
.subsection-content img {
  display: block;
  margin: 20px auto;
  max-width: 100%;
  border-radius: 8px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
}

/* === Package Section Layout === */
.package-section {
  display: flex;
  align-items: flex-start;
  gap: 20px;
  margin-top: 20px;
  flex-wrap: wrap;
}

/* === Package Image === */
.package-image {
  width: 300px;
  max-width: 100%;
  height: auto;
  border-radius: 8px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  flex-shrink: 0;
}

/* === Package Text === */
.package-text {
  flex: 1;
  font-size: 1.1rem;
  color: rgb(60, 60, 80);
}

/* === Image Row Layout === */
.image-row {
  display: flex;
  justify-content: center;
  gap: 20px;
  margin: 30px 0;
  flex-wrap: wrap;
}

.image-row.two-images .image-container {
  flex: 0 1 calc(50% - 10px);
}

/* === Image Grid Layout === */
.image-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
  margin: 30px 0;
}

.image-grid .image-container {
  width: 100%;
  max-width: none;
}

/* === Image Container with Caption === */
.image-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  width: 300px;

  &.wide-image {
    width: auto;
  }
}

/* === Workflow Image === */
.workflow-image {
  width: 100%;
  max-width: 300px;
  height: auto;
  border-radius: 8px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
}

.wide-workflow-image {
  max-width: 800px;
}

/* === Image Caption === */
.image-caption {
  font-size: 0.9rem;
  color: rgb(100, 100, 100);
  margin-top: 5px;
  font-style: italic;
  width: 90%;
  max-width: 270px;
  word-wrap: break-word;

  &.wide {
    max-width: 800px;
  }
}

// /* === Responsive Design === */
// @media (max-width: 768px) {
//   .package-section {
//     flex-direction: column;
//     align-items: center;
//   }

//   .image-container {
//     width: 100%;
//     max-width: 300px;
//     margin: 0 auto;
//   }

//   .image-caption {
//     width: 80%;
//     max-width: 240px;
//   }

//   .package-image {
//     width: 100%;
//   }

//   .image-row {
//     flex-direction: column;
//     align-items: center;
//   }

//   .image-row .image-container {
//     flex: 1 1 100%;
//     margin-bottom: 20px;
//   }

//   .image-grid {
//     grid-template-columns: 1fr;
//   }
// }
