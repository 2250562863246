// Variables
$primary-color: rgb(31, 73, 125);
$text-color: rgb(139, 143, 149);
$background-color: #f9fbfd;
$white: #fff;
$hover-color: rgba(255, 255, 255, 0.3);
$border-color: rgba(255, 255, 255, 0.3);

// Mixins
@mixin flex-container {
  display: flex;
  width: 100%;
  height: 100vh;
}

@mixin box-shadow {
  box-shadow: 2px 0 5px rgba(0, 0, 0, 0.1);
}

.manual-page {
  font-family: Arial, sans-serif;
  line-height: 1.6;
  color: $text-color;
  background-color: $background-color;
  margin: 0;
  padding: 0;
  display: flex;
  height: 100vh;
  width: 100%;

  .container {
    @include flex-container;
  }

  // Main content area
  .main-content {
    flex-grow: 1;
    height: 100vh;
    background-color: $background-color;
    display: flex;
    flex-direction: column;
    padding: 20px;
  }

  .top-header {
    background-color: $white;
    padding: 15px 20px;
    font-size: 1.2em;
    font-weight: bold;
    color: $primary-color;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    margin-bottom: 20px;
  }

  // Content area styles
  .content-area {
    flex-grow: 1;
    overflow-y: auto;
    padding: 20px;
    background-color: $white;
    border-radius: 4px;
    @include box-shadow;
  }

  // Sidebar styles
  .sidebar {
    width: 250px;
    background-color: $primary-color;
    color: $white;
    padding: 12px;
    height: 100vh;
    overflow-y: auto;
    flex-shrink: 0;
    @include box-shadow;
    display: flex;
    flex-direction: column;

    .sidebar-title {
      font-size: 1.2em;
      font-weight: bold;
      color: $white;
      text-align: center;
      margin-bottom: 5px;
      padding-bottom: 6px;
      border-bottom: 1px solid $border-color;
    }

    ul {
      list-style: none;
      padding: 0;
      margin-top: 0;

      li {
        margin: 2px 0;
      }

      a {
        color: $white;
        text-decoration: none;
        font-size: 0.9em;
        display: block;
        padding: 5px 8px;
        font-weight: bold;
        border-radius: 4px;
        transition: background 0.3s ease;

        &:hover {
          background-color: $hover-color;
        }

        &.active-link {
          background-color: $hover-color;
        }
      }
    }
  }

  // Expandable sections
  .expandable {
    cursor: pointer;
    font-size: 0.9em;
    font-weight: bold;
    display: block;
    padding: 5px 8px;
    background-color: rgba(255, 255, 255, 0.2);
    border-radius: 4px;
    transition: background 0.3s;

    &:hover {
      background-color: $hover-color;
    }
  }

  // Submenu styles
  .sub-menu {
    display: none;
    list-style: none;
    padding-left: 8px;
    margin-top: 2px;

    &.expanded {
      display: block;
      animation: fadeIn 0.3s ease-in-out;
    }
  }

  // Special links
  .special-link {
    background-color: rgba(255, 255, 255, 0.2);
    border-radius: 4px;
    padding: 3px 6px;
    margin: 2px 0;
    transition: background 0.3s ease;
    height: 28px;
    display: flex;
    align-items: center;
    cursor: pointer;

    a {
      display: block;
      font-weight: bold;
      padding: 5px 8px;
      color: $white;
      width: 100%;
      text-decoration: none;
    }

    &:hover {
      background-color: $hover-color;
    }
  }

  // Back button
  .back-button {
    background-color: rgba(255, 255, 255, 0.2);
    color: $white;
    border: none;
    padding: 8px 12px;
    border-radius: 4px;
    cursor: pointer;
    font-weight: bold;
    margin: 10px 0;
    width: 100%;
    transition: background-color 0.3s ease;
    display: flex;
    align-items: center;
    justify-content: center;

    &:hover {
      background-color: $hover-color;
    }

    &::before {
      content: "←";
      margin-right: 8px;
    }
  }

  // Content styles
  .subsection-content {
    max-width: 900px;
    margin: 40px auto;
    padding: 30px;
    line-height: 1.7;

    h1 {
      font-size: 2rem;
      color: $primary-color;
      font-weight: bold;
      margin-bottom: 20px;
    }

    h2 {
      font-size: 1.6rem;
      color: lighten($primary-color, 10%);
      font-weight: bold;
      margin-top: 30px;
      margin-bottom: 15px;
    }

    p {
      font-size: 1.1rem;
      color: rgb(60, 60, 80);
      margin-bottom: 15px;
    }

    ul,
    ol {
      padding-left: 25px;
      margin-bottom: 20px;
    }

    li {
      font-size: 1.1rem;
      margin-bottom: 10px;
    }

    a {
      color: $primary-color;
      text-decoration: none;
      border-bottom: 1px solid transparent;
      transition: border-color 0.3s ease;

      &:hover {
        border-bottom-color: $primary-color;
      }
    }
  }

  // Animation
  @keyframes fadeIn {
    from {
      opacity: 0;
      transform: translateY(-3px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
}

// Image layouts
.image-row {
  display: flex;
  justify-content: space-between;
  margin: 30px 0;
  flex-wrap: wrap;
  gap: 20px;

  &.two-images {
    .image-container {
      flex: 0 1 calc(50% - 10px);
    }
  }
}

.image-container {
  flex: 1;
  text-align: center;
  margin-bottom: 20px;
}

.workflow-image {
  max-width: 100%;
  height: auto;
  border-radius: 4px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.image-caption {
  margin-top: 10px;
  font-size: 0.9em;
  color: $text-color;
  font-style: italic;
}

// Responsive styles
@media (max-width: 768px) {
  .container {
    flex-direction: column;
  }

  .sidebar {
    width: 100%;
    height: auto;
    max-height: 300px;
  }

  .main-content {
    height: auto;
  }

  .image-row {
    flex-direction: column;

    .image-container {
      flex: 1 1 100%;
    }
  }
}
