@import "../theme/themeVariables.scss";

.registerPage {
  height: calc(100vh - max(9vh, 90px));
  overflow: auto;

  .pageContentContainer {
    display: flex;
    flex-direction: column;
    align-items: center;

    .detailEntryContainer {
      display: flex;
      flex-direction: column;
      width: 80%;
      max-width: 1050px;
    }

    .questionEntryContainer {
      display: flex;
      flex-direction: column;
      width: 80%;
      max-width: 1050px;
      margin-top: calc(15px + 1vh);
    }

    .entrySection {
      display: flex;
      flex-direction: column;
      margin-top: calc(10px + 1vh);
      width: 100%;

      .emailInUse {
        color: red;
        margin-left: calc(5px + 0.5vw);
        margin-top: 5px;
      }

      .sectionTitle {
        font-size: 1.1em;
        font-weight: 600;
        margin-bottom: calc(5px + 0.5vh);
        margin-left: calc(5px + 0.5vw);
      }

      .entries {
        display: flex;
        .entry {
          flex: 1;
          margin-right: calc(5px + 0.5vw);
          margin-left: calc(5px + 0.5vw);

          .entryField {
            width: 100%;
          }
        }
      }
    }

    .questionSection {
      margin-left: calc(5px + 0.5vw);
      margin-top: calc(10px + 1vh);

      .question {
        font-size: 1.1em;
        font-weight: 600;
        margin-bottom: calc(5px + 0.5vh);
      }

      .replyArea {
        width: 30%;
        min-width: 260px;
      }

      .replyAreaText {
        height: fit-content;
        margin-bottom: calc(5px + 0.5vh);
        .questionNote {
          margin-bottom: calc(10px + 1vh);
        }

        .textArea {
          font-size: 0.9em;
          height: 100px;
          max-width: 100%;
          min-width: 130px;
          min-height: 65px;
          max-height: 205px;
        }
      }

      .privacyPolicy {
        color: $primary;
        cursor: pointer;
        margin-bottom: 5px;
        margin-top: 10px;

        &:last-child {
          margin-bottom: calc(10px + 1vh);
        }
      }
      .privacyPolicy:hover {
        text-decoration: underline;
      }
    }

    .afterConsent {
      margin-left: calc(5px + 0.5vw);
      margin-bottom: calc(20px + 3vh);

      .captchaArea {
        width: 250px;
        height: 100px;
        border: 1px solid black;
      }

      .registerButtonArea {
        margin-top: calc(10px + 1vh);

        .registerButton {
          background-color: $primary;
          color: $lightText;
          width: 15%;
        }
        .registerButton:hover {
          background-color: $primary;
        }
        .registerButton:disabled {
          background-color: darkgrey;
        }
      }
    }

    .missmatch {
      font-size: 1.1em;
      margin-top: calc(5px + 0.2vh);
      // margin-bottom: calc(5px + 1vh);
      margin-left: calc(5px + 0.5vw);
      color: red;
    }
  }
}
