@import "../../../../theme/themeVariables.scss";

.fileProcessWindow {
  display: flex;
  flex-direction: row;
  position: relative;

  .deleteGraphButtonContainer{
    position: absolute;
    top: 0;
    right: 0;
    z-index: 1;
  }

  // .deleteGraphButton {
  //   position: absolute;
  //   top: 5px;
  //   right: 5px;
  //   display: flex;
  //   margin-right: 10px;
  //   margin-left: 5px;
  //   background-color: rgb(233, 2, 2);
  //   border-radius: 6px;
  //   border: 1px solid rgb(182, 182, 182);
  //   cursor: pointer;
  //   z-index: 1;
  // }

  // .deleteGraphIcon {
  //   color: white;
  //   height: 22px;
  //   margin-left: 7px;
  //   margin-right: 7px;
  // }

  .divider {
    width: 100%;
    border-bottom: 1px solid black;
    margin-top: calc(10px + 1vh);
    margin-bottom: calc(10px + 1vh);
  }

  .leftSide {
    flex: 1;
    margin-right: 15px;

    display: flex;
    flex-direction: column;

    .leftTitle {
      font-size: 1.2em;
      font-weight: 600;
    }

    .leftContent {
      border: 1px solid black;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      .buttonArea {
        display: flex;
        justify-content: space-around;
        margin-bottom: 5px;

        // .solveButton {
        //   background-color: $primary;
        // }
      }
    }
  }
  .rightSide {
    flex: 3;
  }
}

.rawAndSolved {
  display: flex;
  .contentArea {
    flex: 1;
    margin-left: 15px;
    margin-right: 15px;

    .title {
      font-size: 1.2em;
    }

    .content {
      font-family: "Consolas", "Monaco", monospace;
      border: 1px solid black;
      height: 165px;
      overflow: auto;
      max-width: 370px;

      .processedTable {
        border-collapse: collapse;
        overflow: hidden;

        th,
        td {
          border: 1px solid black;
          padding: 1px 5px;
          text-align: left;
          border-left: 0;
          border-top: 0;
        }

        th {
          background-color: #f2f2f2;
        }

        tr:nth-child(even) {
          background-color: #f9f9f9;
        }
      }
    }

    pre {
      line-height: 1; // Adjust this value as needed
      margin-top: 0;
      margin-bottom: 0;
    }
  }
}

.processingOptionsSection {
  margin-left: 15px;
  .optionsSection {
    display: flex;
    margin-right: 15px;
    .optionsArea {
      flex: 7;
      .optionsIntro {
        font-size: 1.2em;
        font-weight: 600;
        letter-spacing: 2px;
      }
      .optionRow {
        display: flex;
        justify-content: flex-start;
        align-items: center;

        &.vertical {
          flex-direction: column;
          justify-content: flex-start;
          align-items: flex-start;
        }

        .select {
          width: 75px;
          &.min {
            width: 40px;
          }
          &.mid {
            width: 55px;
          }
          &.big {
            width: 115px;
          }
        }

        .optionTitle {
          margin-right: 10px;
        }

        .infoIcon {
          height: 18px;
          cursor: help;
        }

        .columnOptions {
          display: flex;
          flex-wrap: wrap;

          .columnSelector {
            display: flex;
            flex-direction: column;

            .singleColumn {
              display: flex;
              align-items: center;

              .columnName {
                font-size: 1.1em;
                margin-right: 10px;
              }
            }
          }
        }

        .shiftRow {
          display: flex;
          align-items: center;

          .title {
            margin-right: 10px;
          }

          .valTitle {
            margin-right: 10px;
            margin-left: 10px;
          }

          .valueInput {
            width: 100px;
          }
        }
      }
    }

    .optionLoadingArea {
      flex: 3;

      .loadingAreaTitle {
        font-size: 1.2em;
        font-weight: 600;
      }

      .presetLitContainer {
        border: 1px solid black;
        height: 100%;
        padding-bottom: 5px;
      }
    }
  }

  .buttonArea {
    display: flex;
    margin-top: 15px;

    // .solveButton {
    //   background-color: $primary;
    //   margin-right: 15px;
    // }

    // .loadPresetButton {
    //   background-color: $primary;
    //   margin-right: 15px;
    // }
  }
}

.presetLoader {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  user-select: none;

  .presetButtonArea {
    margin-top: 5px;
    display: flex;
    justify-content: space-around;
  }

  .presetList {
    .presetEntry {
      padding-left: 5px;
      cursor: pointer;
      font-size: 1.1em;
      display: flex;
      align-items: center;
      justify-content: space-between;

      &.selected {
        background-color: $selectedLine;
      }

      .delIcon {
        height: 15px;
        display: none;
      }

      &:hover {
        .delIcon {
          display: block;
        }
      }
    }
  }
}

.fileList {
  max-height: 70vh;
  overflow: auto;
  .fileEntry {
    display: flex;
    justify-content: space-between;
    padding-left: 3px;
    padding-top: 1px;
    padding-bottom: 1px;

    &.selected {
      background-color: $selectedLine;
    }

    &.solved {
      color: gray;
    }

    .fileName {
      font-size: 1em;
    }
  }
}
