.curvesModal {
  position: relative;
  overflow: hidden;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .distTitle {
    font-size: 1.2em;
    font-weight: 600;
    letter-spacing: 0.5px;
    margin-bottom: 5px;
  }

  .distList {
    height: 390px;
    overflow: auto;

    .downloadIcon {
      font-size: 1.2em;
      margin-left: 5px;
      margin-right: 5px;
      cursor: pointer;
    }

    .distListEntry {
      display: flex;
      align-items: center;
      margin-bottom: 2px;
      font-size: 1.2em;
      border-top: 1px solid lightgrey;

      &:first-child {
        border-top: 0;
      }

      .modelNameArea {
        font-size: 0.85em;
        max-width: 150px;
      }

      .curveColorArea {
        height: 15px;
        width: 20px;
        margin-left: 5px;
        border: 1px solid black;
        cursor: pointer;
      }

      .distListEntryIcon {
        display: none;
      }
    }
    .distListEntry:hover {
      .distListEntryIcon {
        display: inline;
        cursor: pointer;
        height: 13px;
        margin-left: 0.3vw;
        margin-bottom: 1px;
      }
    }
  }

  .buttonContainer {
    width: 100%;
    display: flex;
    justify-content: center;
    padding-top: 5px;

    // .distAddButton {
    //   margin: 0;
    // }
  }

  .distOverlay {
    position: absolute;
    z-index: 10;
    bottom: -100%; // start position from outside the div
    left: 0;
    right: 0;
    height: 100%; // to cover the whole settingsModal
    background: rgb(
      255,
      255,
      255
    ); // semi-transparent background, adjust as needed
    border: 1px solid rgba(0, 0, 0, 0.144);
    border-radius: 5px;
    transition: bottom 0.5s; // sliding animation
    padding: 5px;

    &.visible {
      bottom: 0; // final position to show overlay
    }

    .distributionsModal {
      height: 85%;
    }

    .overlayCloseContainer {
      width: 100%;
      height: 50px;
      position: absolute;
      bottom: 0;
      max-height: fit-content;
      display: flex;
      justify-content: center;
    }
  }
}

.settingsModal {
  position: relative;
  overflow: hidden;

  .divider {
    width: 100%;
    border-bottom: 1px solid black;
    margin-top: 10px;
    margin-bottom: 5px;

    &.noTop {
      margin-top: 0;
    }
  }

  .axisGroup {
    .x-data {
      display: flex;
      flex-direction: column;
      margin-top: 0;
    }

    .y-axis {
      display: flex;
      flex-direction: column;
      margin-top: 5px;
    }

    .axisName {
      font-size: 1.1em;
    }

    .rangeVals {
      display: flex;
      margin-top: 5px;

      .rangeValEntry {
        width: 120px;
        margin-left: 5px;
        margin-right: 5px;
      }
    }
  }

  .buttonSection {
    width: 100%;
    display: flex;
    justify-content: space-around;
    padding-top: 5px;
  }
}

.colorPicker {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;

  .chooseColor {
    .react-colorful {
      width: 130px;
      height: 130px;
    }
  }

  .oldVsNewColor {
    display: flex;
    flex-direction: column;
    .colorsArea {
      flex: 2;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      .color {
        width: 100%;
        height: 50%;

        &.top {
          border-radius: 15px 15px 0 0;
        }

        &.bottom {
          border-radius: 0 0 15px 15px;
        }
      }
    }
    .buttonArea {
      flex: 1;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}
