@import "../theme/themeVariables.scss";

.loginPage {
  position: relative;
  height: 100vh;

  .centerGrid {
    height: 70vh;

    .successRecover {
      color: green;
    }
    .failedRecover {
      color: red;
    }
    .loginButton {
      background-color: $primary;
      color: $lightText;
      width: 100%;
    }
    .loginButton:hover {
      background-color: $primary;
    }

    .otherOptions {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      user-select: none;

      .forgot {
        color: $primary;
        cursor: pointer;
      }
      .forgot:hover {
        text-decoration: underline;
      }

      .register {
        display: flex;
        .registerButton {
          margin-left: 8px;
          color: $primary;
          cursor: pointer;
        }
        .registerButton:hover {
          text-decoration: underline;
        }
      }
    }
  }
}
