.legalPage {
  font-family: Arial, sans-serif;
  line-height: 1.6;
  color: #333;
  background-color: #f9fbfd;
  /*max-width: 800px;*/
  margin: 20px;
  padding: 0;
}
h1,
h2,
h3 {
  color: #0056b3;
}
a {
  color: #0056b3;
  text-decoration: none;
}
a:hover {
  color: #2980b9;
  text-decoration: underline;
}
ul,
ol {
  margin: 10px 0;
  padding-left: 20px;
}
.section {
  margin-bottom: 30px;
}
.important {
  background-color: #ffefc6;
  border-left: 4px solid #f5c518;
  padding: 10px 20px;
  margin: 20px 0;
}
.important strong {
  color: #b8860b;
}
.thank-you {
  background-color: #dff9fb; /* Light blue-green background */
  border-left: 6px solid #1abc9c; /* Use a fresher shade of green */
  padding: 20px;
  margin-top: 40px;
  border-radius: 8px;
  font-size: 1.1em;
}
.thank-you p {
  margin-bottom: 15px;
}
.thank-you strong {
  color: #2e7d32;
}

/* new styles for the pricing page*/
// header {
//   background-color: #0073e6;
//   color: white;
//   text-align: center;
//   padding: 1rem 0;
// }

.pricing-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  padding: 2rem;
}

.pricing-table {
  border-collapse: collapse;
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  background-color: white;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
}

.pricing-table th,
.pricing-table td {
  padding: 1rem;
  text-align: center;
  border: 1px solid #ddd;
}

.pricing-table th {
  background-color: #0073e6;
  color: white;
  font-size: 1.1rem;
}

.pricing-table tr:nth-child(even) {
  background-color: #f9f9f9;
}

.pricing-table tr:hover {
  background-color: #f1f1f1;
}

.btn-primary {
  display: inline-block;
  background-color: #0073e6;
  color: white;
  padding: 0.75rem 1.5rem;
  font-size: 1rem;
  border-radius: 5px;
  text-decoration: none;
  transition: background-color 0.3s;
  cursor: pointer;
}

.btn-primary:hover {
  background-color: #005bb5;
}
