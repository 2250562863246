/* feedbackPage.scss (or .css) */
.feedbackPage {
  font-family: Arial, sans-serif;
  line-height: 1.6;
  color: #333;
  background-color: #f9fbfd;
  padding: 20px;

  h1,
  h2,
  h3 {
    color: #0056b3;
  }

  a {
    color: #0056b3;
    text-decoration: none;

    &:hover {
      color: #2980b9;
      text-decoration: underline;
    }
  }

  ul,
  ol {
    margin: 10px 0;
    padding-left: 20px;
  }

  .section {
    margin-bottom: 30px;
  }

  .important {
    background-color: #ffefc6;
    border-left: 4px solid #f5c518;
    padding: 10px 20px;
    margin: 20px 0;

    strong {
      color: #b8860b;
    }
  }

  .thank-you {
    background-color: #dff9fb;
    border-left: 6px solid #1abc9c;
    padding: 20px;
    margin-top: 40px;
    border-radius: 8px;
    font-size: 1.1em;

    p {
      margin-bottom: 15px;
    }

    strong {
      color: #2e7d32;
    }
  }

  /* If you truly need the "header" styles only on the feedback page,
       nest it under .feedbackPage as well: */
  header {
    background-color: #0073e6;
    color: white;
    text-align: center;
    padding: 1rem 0;
  }

  /* Any other classes that should only apply on the Feedback page */
  .pricing-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    padding: 2rem;
  }

  .pricing-table {
    border-collapse: collapse;
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
    background-color: white;
    border-radius: 10px;
    overflow: hidden;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);

    th,
    td {
      padding: 1rem;
      text-align: center;
      border: 1px solid #ddd;
    }

    th {
      background-color: #0073e6;
      color: white;
      font-size: 1.1rem;
    }

    tr:nth-child(even) {
      background-color: #f9f9f9;
    }

    tr:hover {
      background-color: #f1f1f1;
    }
  }

  .btn-primary {
    display: inline-block;
    background-color: #0073e6;
    color: white;
    padding: 0.75rem 1.5rem;
    font-size: 1rem;
    border-radius: 5px;
    text-decoration: none;
    transition: background-color 0.3s;

    &:hover {
      background-color: #005bb5;
    }
  }

  /* Only style form elements under .feedbackPage */
  form {
    width: 90%;
    max-width: 1000px;
    margin: 0 auto;
    background: #fff;
    padding: 20px;
    border-radius: 5px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  }

  .form-group {
    display: block;
    width: 100%;
    flex-direction: column;
    margin-bottom: 15px;
  }

  label {
    display: block;
    margin-bottom: 5px;
    font-weight: bold;
  }

  input,
  select,
  textarea {
    width: 100%;
    box-sizing: border-box;
    max-width: 1200px;
    padding: 10px;
    margin-top: 5px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }

  textarea {
    resize: vertical;
  }

  .rating {
    display: flex;
    flex-direction: row;
    gap: 10px;

    label {
      font-weight: normal;
    }
  }

  button {
    background: #007bff;
    color: #fff;
    border: none;
    padding: 10px 15px;
    font-size: 16px;
    border-radius: 5px;
    cursor: pointer;
    display: block;
    width: 100%;

    &:hover {
      background: #0056b3;
    }
  }
}
