@import "../../../theme/themeVariables.scss";

.fitSection {
  min-height: 300px;
  max-height: 600px;
  background-color: white;
  text-align: left;
  width: 460px;

  display: flex;
  justify-content: center;

  .container {
    width: 100%;
  }
}

.fitOptions {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;

  .choicesAndTerms {
    display: flex;
    width: 100%;
    justify-content: space-around;
    height: 87%;
  }

  .definedTerms {
    height: 100%;
    width: 100%;
    margin-bottom: 15px;
    .termsHeader {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .title {
        font-size: 1.2em;
        margin-bottom: 10px;
        padding-left: 8px;
      }
    }

    .noTermsChosen {
      font-style: italic;
      text-align: center;
      color: $greyText;
    }

    .totalsContainer {
      display: flex;
      justify-content: flex-end;
      align-items: flex-end;
      margin-top: 5px;
      flex-direction: column;
      .total {
        display: flex;
        padding-right: 10%;
      }
    }
  }

  .fitButtonSection {
    display: flex;
    justify-content: center;
    bottom: 0;
    width: 100%;

    // .fitButton {
    //   background-color: $primary;
    //   padding: 5px 30px 5px 30px;
    //   border-radius: 10px;
    //   font-size: 0.8em;
    //   letter-spacing: 0.5px;
    // }
    // .fitButton:hover {
    //   background-color: $complimentary;
    // }
  }

  .choicesSection {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    width: 95%;
    min-width: 275px;
    max-width: 410px;
    height: 100%;

    .addTermSection {
      display: flex;
      justify-content: center;
      width: 100%;

      // .addButton {
      //   cursor: pointer;
      //   background-color: $primary;
      //   padding: 3px 20px 3px 20px;
      //   border-radius: 10px;
      //   color: white;
      // }
      // .addButton:hover {
      //   background-color: $complimentary;
      // }
    }

    .choiceLists {
      width: 100%;
      display: flex;
      justify-content: space-between;

      .choiceListContainer {
        width: 48%;
        margin-bottom: 20px;
        .choiceListTitle {
          font-size: 1.2em;
          margin-bottom: 10px;
          margin-top: 5px;
          margin-left: 5px;
        }
        .choiceList {
          border: 1px solid rgb(204, 204, 204);
          padding: 5px;
          border-radius: 7px;
          height: 195px;
          overflow: auto;

          .modelOutputAccordion {
            border: 1px solid black;
            margin-bottom: 5px;

            .accordionSummary {
              padding: 0px 16px;
              margin: 0;
              min-height: 24px;

              &.selected {
                border-radius: 3px;
                background-color: $complimentaryLight;
              }

              .MuiAccordionSummary-content {
                margin: 0;
              }
            }

            .accordionDetails {
              padding: 0px 16px 8px 16px;
              border-top: 1px solid black;
              margin-top: 2px;

              .outputItem {
                padding-bottom: 2px;
                margin-bottom: 2px;
                margin-top: 4px;
                border-bottom: 1px solid darkgray;
                cursor: pointer;

                &:hover {
                  background-color: $secondary;
                }

                &.lastItem {
                  border-bottom: 0;
                }

                &.selected {
                  background-color: $complimentaryLight;
                }
              }
            }
          }

          .singleModelOutput {
            border: 1px solid black;
            margin-bottom: 5px;
            padding: 0px 16px;
            border-radius: 4px;
            cursor: pointer;
            user-select: none;

            &.selected {
              background-color: $complimentaryLight;
            }
          }

          .outputTooltip {
            background-color: rgba(0, 0, 0, 0.866);
            font-size: 1.2em;
            padding: 10px 15px;
            border-radius: 5px;
            margin-bottom: -20px;
          }

          .modelOutputAccordion.Mui-expanded {
            .accordionSummary {
              padding: 0px 16px;
              margin: 0;
              min-height: 24px;

              .MuiAccordionSummary-content {
                margin: 0;
              }
            }
          }

          .listItem {
            cursor: pointer;
            padding: 2px 3px 2px 3px;
            border-radius: 5px;
          }

          .listItem:hover {
            background-color: $secondary;
          }

          .selected {
            background-color: $complimentaryLight;
          }
        }
      }
    }
  }

  .termOptionsSection {
    display: flex;
    flex-direction: column;
    height: 80%;

    .optionTitle {
      font-size: 1.1em;
    }

    .option {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      font-size: 0.9em;
      margin-top: 5px;
      margin-bottom: 5px;

      .text {
        margin-left: 2px;
        margin-right: 2px;
      }

      .text:first-child {
        margin-left: 0;
      }

      .optionEntry {
        width: 85px;
      }
    }
  }
}

.addOverlay {
  position: absolute;
  bottom: 0;
  z-index: 10;
  left: 0;
  right: 0;
  height: 300px;
  background: rgb(
    255,
    255,
    255
  ); // semi-transparent background, adjust as needed
  border: 1px solid rgba(0, 0, 0, 0.144);
  border-radius: 5px;
  padding: 5px;

  display: flex;
  align-items: center;
  justify-content: center;

  &.options {
    height: 260px;
  }

  .overlayCloseContainer {
    width: 100%;
    height: 55px;
    position: absolute;
    bottom: 0;
    max-height: fit-content;
    display: flex;
    justify-content: center;

    sup {
      margin-top: -10px;
    }
  }
}

.termsContainer {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-content: center;
  overflow: auto;
  margin-top: 5px;
  max-height: calc(100% - 60px);
  width: 100%;

  .x2Chip {
    border: 1px solid black;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 8px;
    padding: 5px;
    border-radius: 10px;
    background-color: $secondary;
    min-height: 70px;
    cursor: pointer;
    user-select: none;

    &.notSelected {
      background-color: white;
    }

    .chiValues {
      font-size: 1em;
      display: flex;
      flex-direction: column;
      align-items: flex-end;

      .valueEntry {
        display: flex;
        justify-content: center;
        align-items: baseline;
        .title {
          margin-bottom: 0;
          padding-left: 5px;
        }

        .value {
          &.greyOut {
            color: rgb(158, 158, 158);
            font-weight: 600;
          }
        }
      }
    }

    input[type="number"]::-webkit-inner-spin-button,
    input[type="number"]::-webkit-outer-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    input[type="number"] {
      appearance: textfield;
      -moz-appearance: textfield;
    }

    .namesContainer {
      display: flex;
      flex-direction: column;
      // justify-content: center;
      // align-items: center;
      flex: 5;
      padding-left: 10px;

      .nameSeparator {
        margin-left: 5px;
        margin-right: 5px;
        margin-top: -1px;
        font-weight: 600;
      }

      .modelAndQuantity {
        display: flex;
        align-items: center;

        .spacer {
          border-left: 1px solid black;
          height: 20px;
          margin-left: 5px;
          margin-right: 5px;
        }

        .quantityName {
          width: 95px;
        }
      }

      .name {
        width: 115px;
      }

      .name:first-child {
        margin-bottom: 3px;
      }
    }

    .entriesContainer {
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex: 3;
      padding-right: 10px;
    }

    .deleteArea {
      flex: 0.5;
      display: flex;
      justify-content: center;

      .deleteIcon {
        cursor: pointer;
        color: $warn;
      }

      .deleteOverlay {
        height: 24px;
        width: 24px;
        z-index: 10;
        position: absolute;
      }
    }
  }
}
