@keyframes honeycomb {
  0%, 20%, 80%, 100% {
    opacity: 0;
    transform: scale(0);
  }

  30%, 70% {
    opacity: 1;
    transform: scale(1);
  }
}

.hex-text-loader {
  display: flex;
  align-items: center;
  justify-content: center;

  .hex-honeycomb {
    height: 10px;
    position: relative;
    width: 10px;

    div {
      animation: honeycomb 2.1s infinite backwards;
      background: #145d9d;
      height: 5px;
      margin-top: 2.5px;
      position: absolute;
      width: 10px;
      box-sizing: border-box;

      &:after, &:before {
        content: '';
        border-left: 5px solid transparent;
        border-right: 5px solid transparent;
        position: absolute;
        left: 0;
        right: 0;
      }

      &:after {
        top: -2.5px;
        border-bottom: 2.5px solid #145d9d;
      }

      &:before {
        bottom: -2px;
        border-top: 2.5px solid #145d9d;
      }

      &:nth-child(1) {
        animation-delay: 0s;
        left: -12px;
        top: 0;
      }

      &:nth-child(2) {
        animation-delay: 0.1s;
        left: -6px;
        top: 10px;
      }

      &:nth-child(3) {
        animation-delay: 0.2s;
        left: 6px;
        top: 10px;
      }

      &:nth-child(4) {
        animation-delay: 0.3s;
        left: 12px;
        top: 0;
      }

      &:nth-child(5) {
        animation-delay: 0.4s;
        left: 6px;
        top: -10px;
      }

      &:nth-child(6) {
        animation-delay: 0.5s;
        left: -6px;
        top: -10px;
      }

      &:nth-child(7) {
        animation-delay: 0.6s;
        left: 0;
        top: 0;
      }
    }
  }

  .loader-text {
    margin-left: 20px;
    font-size: 1.1em;
    color: #000;
  }
}
