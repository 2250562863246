$primary: #145d9d;
$secondary: #d7e7f5;
$complimentaryLowOp: #556cd60a;
$complimentary: #3b4b95;
$complimentaryLight: #92dbfd;
$superLight: #edf7ff;

$lightText: #ffffff;
$greyText: rgb(139, 139, 139);

$warn: #803333;

$selectedLine: #cce5ff;

$superLightBg: #e6eaf1;

@mixin even-box-shadow($blur-radius: 10px, $spread-radius: 0px, $color: rgba(0, 0, 0, 0.25)) {
  box-shadow: 0 0 $blur-radius $spread-radius $color;
}