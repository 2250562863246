.confirmEmailPage{
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;

    .text{
        font-size: 1.4em;
        font-weight: 500;
    }
}