@import "../../theme/themeVariables.scss";

.draggableWindow {
  position: absolute;
  border: 1px solid $primary;
  border-radius: 10px 10px 5px 5px;

  .dragHandler {
    cursor: move;
    width: 100%;
    height: 30px;
    background-color: $secondary;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 10px 10px 0 0;
  }

  .dragContainer {
    position: relative;
    height: calc(100% - 30px);
    width: 100%;
    background-color: $superLight;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5px;
    border-radius: 0 0 5px 5px;

    .extraSection {
      position: absolute;
      bottom: 0;
      left: 0;
      padding-left: 10px;
    }
  }

  .dataIcons {
    margin-left: 10px;
    height: 100%;
    display: flex;
    align-items: center;
    flex-direction: row;

    .addIcon {
      cursor: pointer;
    }

    .settingsIcon {
      cursor: pointer;
      height: 70%;
    }

    .curvesIcon {
      cursor: pointer;
      height: 80%;
      margin-left: 5px;
    }

    // .ramanAutofit {
    //   padding: 0;
    //   font-size: 0.75em;
    // }
  }

  .dragWinManageIcons {
    height: 100%;
    display: flex;
    align-items: center;
    flex-direction: row;

    // .export { 
    //   padding: 0;
    //   font-size: 0.7em;
    // }

    .sizeIcon {
      cursor: pointer;
      height: 60%;
      margin-right: 5px;
    }

    .deleteGraphButtonContainer{
      margin-right: 10px;
      margin-left: 5px;
    }

    // .deleteGraphButton {
    //   display: flex;
    //   background-color: rgb(233, 2, 2);
    //   border-radius: 6px;
    //   border: 1px solid rgb(182, 182, 182);
    //   cursor: pointer;
    // }

    // .deleteGraphIcon {
    //   color: white;
    //   height: 22px;
    //   margin-left: 7px;
    //   margin-right: 7px;
    // }
  }

  .windowTitle {
    margin-left: 5px;
  }
}

.plotWrapper {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  .plotPlaceholder {
    width: 100%;
    height: 100%;
    filter: blur(1px);
  }
}

.graphSection {
  position: relative;
  .graphWindowBar {
    width: 100%;
    height: 45px;
    display: flex;
    align-items: center;

    .addGraphButton {
      display: inline;
      padding: 5px 20px 5px 20px;
      border-radius: 10px;
      font-weight: 600;
      letter-spacing: 0.5px;
      font-size: 0.9em;
      margin-left: calc(10px + 2vw);
      background-color: $primary;
      color: $lightText;
      cursor: pointer;
      user-select: none;
    }

    .addGraphButton:hover {
      background-color: $complimentary;
    }
  }
}

.distributionsModal {
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100%; // Ensure the parent has a defined height

  .fileDistributions {
    .fileDistTitle {
      font-weight: 600;
      font-size: 1.2em;
      margin-bottom: 5px;
    }

    .fileDistList {
      max-height: 100px;
      overflow: auto;
    }
  }

  .modelDistributions {
    flex-grow: 1; // Allow this element to grow and fill available space
    display: flex;
    flex-direction: column;

    .modelDistTitle {
      font-weight: 600;
      font-size: 1.2em;
      margin-bottom: 5px;
    }

    .modelDistList {
      flex-grow: 1; // Ensure this element also grows to fill available space
      max-height: 250px;
      overflow: auto;
    }
  }
}

.fileEntry {
  user-select: none;
  cursor: pointer;
}

.fileEntry:hover {
  background-color: $secondary;
}

.graphModelEntry {
  user-select: none;
  cursor: pointer;
}

.graphModelEntry:hover {
  background-color: $secondary;
}

.modelOutputAccordion {
  border: 1px solid black;
  margin-bottom: 5px;

  .accordionSummary {
    padding: 0px 16px;
    margin: 0;
    min-height: 24px;

    .MuiAccordionSummary-content {
      margin: 0;
    }
  }

  .accordionDetails {
    padding: 0px 16px 8px 16px;
    border-top: 1px solid black;
    margin-top: 2px;

    .outputItem {
      padding-bottom: 2px;
      margin-bottom: 2px;
      margin-top: 4px;
      border-bottom: 1px solid darkgray;
      cursor: pointer;

      &:hover {
        background-color: $secondary;
      }

      &.lastItem {
        border-bottom: 0;
      }
    }
  }
}

.singleModelOutput {
  border: 1px solid black;
  margin-bottom: 5px;
  padding: 0px 16px;
  border-radius: 4px;
  cursor: pointer;
  user-select: none;
}

.outputTooltip {
  background-color: rgba(0, 0, 0, 0.866);
  font-size: 1.2em;
  padding: 10px 15px;
  border-radius: 5px;
  margin-bottom: -20px;
}

.modelOutputAccordion.Mui-expanded {
  .accordionSummary {
    padding: 0px 16px;
    margin: 0;
    min-height: 24px;

    .MuiAccordionSummary-content {
      margin: 0;
    }
  }
}

.delModal {
  height: 100%;

  .delAsk {
    font-size: 1.1em;
  }

  .delButtons {
    display: flex;
    justify-content: space-around;
    margin-top: 15px;

    .button {
      padding: 3px 15px 3px 15px;
      border: 1px solid black;
      border-radius: 7px;
      width: 60px;
      text-align: center;
      cursor: pointer;
      background-color: white;
    }

    .button:hover {
      background-color: rgb(233, 233, 233);
    }
  }
}

.grapWindowResizableBox .react-resizable-handle {
  z-index: 4000;
  scale: 1.5;
  margin-right: 5px;
  margin-bottom: 5px;
}

.fitStopWindow {
  position: absolute;
  width: 450px;
  height: 100px;
  bottom: 10%;
  right: 40%;
  left: 40%;
  padding: 7px;
  border: 1px solid rgb(179, 179, 179);
  border-radius: 10px;
  box-shadow: 1px 1px 5px rgb(201, 201, 201);
  background-color: white;
  display: flex;
  justify-content: space-between;

  align-items: center;
  z-index: 9999;

  .fitInfo {
    .text {
      font-size: 1.35em;
      font-weight: 600;
      letter-spacing: 0.5px;
    }
    .iterationCount {
      font-size: 1.25em;
    }
  }

  .buttonArea {
    .stopFitButton {
      font-size: 1.25em;
    }
  }
}
