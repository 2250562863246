.solvedClash {
  display: flex;
  margin-bottom: 5px;
  margin-left: 5px;
  margin-right: 5px;

  .value {
    padding: 3px 10px;
  }

  .solutionIcon {
    margin-left: 5px;
    margin-right: 5px;
  }

  .tooltip {
    background-color: rgba(0, 0, 0, 0.748);
    font-size: 1.2em;
    padding: 10px 15px;
    border-radius: 5px;
    margin-bottom: -20px;
    z-index: 9999;
  }
}

.clashToSolve {
  display: flex;
  flex-direction: column;

  .groupNumberSection {
    display: flex;
    justify-content: center;
    margin-bottom: calc(5px + 1vh);

    .text {
      font-size: 1.4em;
    }

    .groupNumber {
      font-size: 1.2em;
      margin-left: 5px;
      padding: 2px 15px;
    }
  }

  .header {
    margin-top: calc(5px + 1.5vh);
    margin-bottom: calc(5px + 0.2vh);
    font-size: 1.3em;
  }

  .values {
    display: flex;

    .valueSection {
      display: flex;
      margin-right: 15px;
      margin-left: 15px;
      font-size: 1.2em;

      .valueTitle {
        margin-right: 5px;
      }

      .value {
        font-weight: 600;
      }
    }
  }

  .solutionArea {
    display: flex;
    justify-content: center;
    margin-bottom: calc(5px + 0.5vh);
  }

  .newGroupChoice {
    display: flex;
    flex-direction: column;

    .newGroupTitle {
      margin-bottom: calc(5px + 0.5vh);
      font-size: 1.3em;
    }

    .choosingGroup {
      display: flex;

      .selectArea {
        flex: 1;
        display: flex;
        justify-content: center;

        .formControl {
          width: 70px;

          .select {
            text-align: center;
          }
        }
      }

      .buttonContainer {
        flex: 1;
        display: flex;
        justify-content: center;
      }
    }
  }
}

.groupClashWindow {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .solvingSection {
    margin-top: calc(20px + 4vh);
    display: flex;
    justify-content: space-around;

    .solved {
      .solvedHeader {
        font-size: 1.2em;
      }
    }

    .toSolve {
      .conterContainer {
        display: flex;
        border-bottom: 1px solid black;
        margin-bottom: 10px;

        .counterTitle {
          font-size: 1.4em;
          margin-right: 5px;
        }

        .counterVal {
          font-size: 1.4em;
          font-weight: 600;
        }
      }
    }

    .noSolutions {
      font-size: 1.3em;
    }
  }

  .solutionButtons {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: calc(20px + 4vh);
    margin-bottom: calc(20px + 4vh);

    .solutionButton {
      width: 20ch;
      font-size: 1.2em;
    }
  }
}
