.demo {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;

  .disclaimer {
    margin-left: 5px;
    margin-right: 5px;
    font-style: italic;
  }

  .buttonsContainer {
    .buttonSection {
      display: flex;
      flex-direction: column;
      margin-bottom: 10px;

      &:last-child {
        margin-bottom: 0;
      }

      .buttonWithText {
        display: flex;
        justify-content: flex-start;
        align-items: center;

        // .button {
        //   min-width: 67px;
        // }

        .text {
          font-style: italic;
        }
      }

      .textAfterClick {
        padding: 5px;
        font-size: 0.8em;
      }
    }
  }
}
