@import "../../theme/themeVariables.scss";

.privacyPolicyModal {
  padding: 5px;
}

.packageContainer {
  position: relative;
  width: 450px;

  .package {
    margin-top: calc(10px + 0.5vh);
    margin-bottom: calc(10px + 0.5vh);
    height: fit-content;
    border: 1px solid black;
    border-radius: 10px;
    display: flex;
    flex-direction: column;

    &.expired {
      filter: contrast(0.05);
    }

    .packageTitle {
      font-size: 1.1em;
      border-bottom: 1px solid black;
      padding-left: 15px;
      padding-top: 7px;
      padding-bottom: 7px;

      // .titleAndQuest{
      //   display: flex;
      //   align-items: center;

      //   .icon{
      //     margin-right: 5px;
      //     cursor: pointer;
      //   }
      // }

      &.offer {
        display: flex;
        justify-content: space-between;
        margin-top: 0;
      }

      .activateButton {
        font-size: 0.8em;
        margin-right: 15px;
        border: 1px solid black;
        padding: 2px 7px;
        border-radius: 5px;
        background-color: $primary;
        color: $lightText;
        user-select: none;
        cursor: pointer;

        &:hover {
          background-color: $complimentary;
          scale: 103%;
          box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.3);
        }
      }
    }

    .packageDetails {
      padding-left: 15px;
      padding-top: 7px;
      padding-bottom: 7px;

      // .timeLeft{

      // }

      .moreSection {
        width: 100%;
        display: flex;
        margin-top: 5px;
        margin-bottom: 5px;
        .moreButton {
          padding: 2px 10px;
          background-color: $primary;
          color: $lightText;
          border-radius: 5px;
          border: 1px solid black;
          cursor: pointer;
          font-size: 0.9em;

          &:hover {
            background-color: $complimentary;
            scale: 103%;
            box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.3);
          }
        }
      }
    }

    .offer {
      display: flex;
      // justify-content: space-around;
      justify-content: flex-start;
      font-size: 1.1em;
      margin-top: calc(5px + 0.5vh);
    }

    .adminSection {
      border-top: 1px solid black;
      margin-top: 5px;
      padding-left: 10px;
      padding-bottom: 10px;

      .note {
        font-weight: 600;
        margin-top: 5px;
        text-align: center;
      }

      .optionsSection {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        margin-top: 10px;
        width: 100%;

        .option {
          width: 100%;
          display: flex;
          justify-content: center;
          align-items: center;

          .addButton {
            margin-left: 5px;
            margin-right: 15px;
          }

          .dateSelection {
            display: flex;
            align-items: center;
            width: 90%;

            .title {
              flex: 1;
            }

            .days {
              flex: 2;
            }

            .monts {
              flex: 2;
            }

            .timeInput {
              width: 70%;
            }
          }
        }
      }
    }
  }

  .renewSection {
    position: absolute;
    user-select: none;
    bottom: 0;
    right: 0;
    margin-right: 15px;
    margin-bottom: 5px;

    .renewButton {
      padding: 2px 10px;
      background-color: $primary;
      color: $lightText;
      border-radius: 5px;
      border: 1px solid black;
      cursor: pointer;
      font-size: 0.9em;

      &:hover {
        background-color: $complimentary;
        scale: 103%;
        box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.3);
      }
    }
  }
}

.editUser {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;

  .sectionTitle {
    font-size: 1.5em;
    font-weight: 600;
    margin-bottom: calc(10px + 1vh);
  }

  .divider {
    border-bottom: 1px solid black;
    width: 100%;
    margin-top: 25px;
    margin-bottom: 25px;
  }

  .subSectionTitle {
    font-size: 1.1em;
    font-weight: 600;
    margin-bottom: 10px;
  }

  .detailSection {
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 80%;
    height: 90%;

    .generalDetails {
      flex: 2;
    }

    .adminDetails {
      flex: 1;
    }
  }

  .packageSection {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .packagesContainer {
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }

    .availablePackages {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;

      .packages {
        // height: 400px;
        overflow: auto;
      }
    }
  }

  .deleteUserButton {
    background-color: red;
  }
}

.detail {
  margin-bottom: calc(5px + 0.2vh);
  padding: 6px;

  .title {
    font-size: 1.2em;
  }

  .nonEditEntry {
    font-size: 1.1em;
    text-decoration: underline;
  }
}

.userData {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
}

.compareChanges {
  .title {
    font-size: 1.3em;
    margin-bottom: 15px;
  }

  .changesList {
    display: flex;
    flex-direction: column;

    .listEntry:first-child {
      border-top: 1px solid black;
      padding-top: 5px;
    }

    .listEntry {
      display: flex;
      flex-direction: column;
      padding-bottom: 5px;
      border-bottom: 1px solid black;
      margin-top: 10px;
      margin-bottom: 5px;

      .entryTitle {
        font-size: 1.1em;
        margin-bottom: 5px;
      }

      .valueContainer {
        display: flex;

        .value {
          flex: 2;
        }

        .icon {
          flex: 1;
        }
      }
    }
  }
}

.changesModal {
  .confirmRevertSection {
    margin-top: 15px;
    display: flex;
    justify-content: space-around;
  }
}

.passwordEntry {
  .entrySection {
    display: flex;
    flex-direction: column;
    margin-top: calc(10px + 1vh);
    width: 100%;

    .sectionTitle {
      font-size: 1.1em;
      font-weight: 600;

      &.flat {
        margin-bottom: calc(5px + 0.5vh);
        margin-left: calc(5px + 0.5vw);
      }

      &.stacked {
        font-size: 1.5em;
      }
    }

    .entries {
      display: flex;

      &.stacked {
        flex-direction: column;
      }

      .entry {
        flex: 1;

        &.flat {
          margin-right: calc(5px + 0.5vw);
          margin-left: calc(5px + 0.5vw);
        }

        &.stacked {
          margin-top: calc(5px + 1vh);
          margin-bottom: calc(5px + 1vh);
        }

        .entryField {
          width: 100%;
        }
      }
    }
  }

  .missmatch {
    font-size: 1.1em;
    margin-top: calc(5px + 0.2vh);
    // margin-bottom: calc(5px + 1vh);
    margin-left: calc(5px + 0.5vw);
    color: red;
  }
}
