@import "../../theme/themeVariables.scss";

.windowSelector {
  width: 100%;
  display: flex;
  justify-content: space-around;
  height: 45px;
  margin-top: 51px;
  z-index: 1001;
  // position: relative;
  position: absolute;

  .switchPlatter {
    width: 100%;
    border-bottom: 1px solid black;
    height: 25px;
    position: absolute;
    background-color: $superLightBg;
  }

  .switches {
    z-index: 5;
    width: 100%;
    display: flex;
    justify-content: center;

    .switch {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 155px;
      height: 35px;
      // border-collapse: collapse;
      border-bottom: 2px solid $complimentary;
      border-left: 1px solid $complimentary;
      border-right: 1px solid $complimentary;
      border-radius: 0px 0px 10px 10px;
      background-color: white;
      font-size: 1.15em;
      letter-spacing: 2px;
      cursor: pointer;
      user-select: none;
      transition: 100ms;

      &:hover {
        height: 37px;
        box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.3);
      }

      &.open {
        height: 45px;
        background-color: $selectedLine;
        box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.3);
      }

      &.disabled {
        color: rgb(219, 219, 219);
      }
    }
  }
}
