@import "../../theme/themeVariables.scss";

.topBarContainer {
  display: flex;
  justify-content: space-between;
  min-height: 50px !important;

  .logoPlusButtons {
    display: flex;
    align-items: center;

    .appBarLogo {
      padding: 10px;
      max-width: 220px;
      max-height: 50px;
    }

    .toAlphaManual {
      margin-left: 5px;
      color: black;
      cursor: pointer;
    }
  }

  .buttonsAndMenuContainer {
    user-select: none;
    display: flex;
    align-items: center;
    .alertsContainer {
      position: relative;
      display: flex;
      align-items: center;
      .alertIcon {
        max-height: 35px;
        margin-right: 1vw;
        margin-bottom: 0.1vh;
        cursor: pointer;
      }
      .newWarnCount {
        position: absolute;
        cursor: pointer;
        padding: 0px 7px 0px 7px;
        border: 1px solid;
        background-color: red;
        border-radius: 35px;
        font-size: 0.9em;
        margin-left: -10px;
        margin-top: -20px;
      }
    }

    .userIcon {
      color: black;
      height: 30px;
      width: 30px;
      user-select: none;
      cursor: pointer;
    }
  }
  .warningModalOverlay {
    color: transparent;
  }
}

.warningList {
  padding-top: calc(0.5vh + 15px);

  .closeButtonContainer{
    position: absolute;
    top: 0;
    right: 0;
    margin-right: 0.5vw;
    margin-top: 0.5vh;
  }

  // .closeIcon {
  //   cursor: pointer;
  //   max-width: 15px;
  //   position: absolute;
  //   top: 0;
  //   right: 0;
  //   margin-right: 0.5vw;
  //   margin-top: 0.5vh;
  //   opacity: 0.9;
  //   filter: grayscale(0.5);
  // }

  // .clearAllButton {
  //   cursor: pointer;
  //   position: absolute;
  //   top: 0;
  //   left: 0;
  //   margin-left: 0.8vw;
  //   margin-top: 0.5vh;
  //   padding: 0px 7px 0px 7px;
  //   border: 1px solid black;
  //   border-radius: 7px;
  //   color: white;
  //   background-color: $warn;
  // }

  .warningEntry {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 0.4vh;
    border: 1px solid black;
    border-radius: 7px;
    padding-top: 2px;
    padding-bottom: 5px;
    padding-left: 5px;
    padding-right: 5px;
    .messageIcon {
      max-width: 25px;
    }
    .messageContainer {
      width: 275px;
      .message {
        margin-left: 5px;
      }
      .bottomPart {
        margin-top: 5px;
        display: flex;
        justify-content: space-between;
        .time {
          text-align: right;
        }
      }
    }
    .clearWarningContainer {
      display: flex;
      justify-content: flex-end;
      margin-top: 3px;
      margin-right: 3px;
      .clearWarning {
        cursor: pointer;
        max-width: 10px;
        filter: grayscale(1);
      }
    }
  }
}

.settingsModal {
  display: flex;
  // justify-content: space-between;
  flex-direction: column;
  height: 100%;
  width: 100%;

  .settings {
    flex: 5;

    .title {
      font-size: 1.2em;
      margin-bottom: 5px;
    }

    .setting {
      display: flex;
      align-items: flex-end;

      .unitSelectorLabel {
        margin-right: 10px;
      }

      .x_unit_select {
        height: 25px;
      }
    }
  }

  .buttons {
    flex: 1;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
  }
}

.feedbackModal {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .text {
    font-size: 1.2em;
  }

  .subText {
    font-size: 0.9em;
    color: gray;
    font-style: italic;
  }
}
