.loader-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(211, 211, 211, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  z-index: 9999;

  .loader {
    position: relative;
    scale: 75%;
  }

  .loader-text {
    margin-top: 80px;
    font-size: 1.5em;
    color: #000;
    background-color: #d1d1d1c4;
    text-align: center;
    padding: 2px 5px;
    border-radius: 5px;
  }

  .socket {
    width: 200px;
    height: 200px;
    position: absolute;
    left: 50%;
    margin-left: -100px;
    top: 50%;
    margin-top: -100px;
  }

  .hex-brick {
    background: #000000;
    width: 30px;
    height: 17px;
    position: absolute;
    top: 5px;
    animation-name: fade00;
    animation-duration: 2s;
    animation-iteration-count: infinite;
    -webkit-animation-name: fade00;
    -webkit-animation-duration: 2s;
    -webkit-animation-iteration-count: infinite;
  }

  .h2 {
    transform: rotate(60deg);
    -webkit-transform: rotate(60deg);
  }

  .h3 {
    transform: rotate(-60deg);
    -webkit-transform: rotate(-60deg);
  }

  .gel {
    height: 30px;
    width: 30px;
    transition: all 0.3s;
    -webkit-transition: all 0.3s;
    position: absolute;
    top: 50%;
    left: 50%;
  }

  .center-gel {
    margin-left: -15px;
    margin-top: -15px;
    animation-name: pulse00;
    animation-duration: 2s;
    animation-iteration-count: infinite;
    -webkit-animation-name: pulse00;
    -webkit-animation-duration: 2s;
    -webkit-animation-iteration-count: infinite;
  }

  .c1 {
    margin-left: -47px;
    margin-top: -15px;
  }

  .c2 {
    margin-left: -31px;
    margin-top: -43px;
  }

  .c3 {
    margin-left: 1px;
    margin-top: -43px;
  }

  .c4 {
    margin-left: 17px;
    margin-top: -15px;
  }

  .c5 {
    margin-left: -31px;
    margin-top: 13px;
  }

  .c6 {
    margin-left: 1px;
    margin-top: 13px;
  }

  .c7 {
    margin-left: -63px;
    margin-top: -43px;
  }

  .c8 {
    margin-left: 33px;
    margin-top: -43px;
  }

  .c9 {
    margin-left: -15px;
    margin-top: 41px;
  }

  .c10 {
    margin-left: -63px;
    margin-top: 13px;
  }

  .c11 {
    margin-left: 33px;
    margin-top: 13px;
  }

  .c12 {
    margin-left: -15px;
    margin-top: -71px;
  }

  .c13 {
    margin-left: -47px;
    margin-top: -71px;
  }

  .c14 {
    margin-left: 17px;
    margin-top: -71px;
  }

  .c15 {
    margin-left: -47px;
    margin-top: 41px;
  }

  .c16 {
    margin-left: 17px;
    margin-top: 41px;
  }

  .c17 {
    margin-left: -79px;
    margin-top: -15px;
  }

  .c18 {
    margin-left: 49px;
    margin-top: -15px;
  }

  .c19 {
    margin-left: -63px;
    margin-top: -99px;
  }

  .c20 {
    margin-left: 33px;
    margin-top: -99px;
  }

  .c21 {
    margin-left: 1px;
    margin-top: -99px;
  }

  .c22 {
    margin-left: -31px;
    margin-top: -99px;
  }

  .c23 {
    margin-left: -63px;
    margin-top: 69px;
  }

  .c24 {
    margin-left: 33px;
    margin-top: 69px;
  }

  .c25 {
    margin-left: 1px;
    margin-top: 69px;
  }

  .c26 {
    margin-left: -31px;
    margin-top: 69px;
  }

  .c27 {
    margin-left: -79px;
    margin-top: -15px;
  }

  .c28 {
    margin-left: -95px;
    margin-top: -43px;
  }

  .c29 {
    margin-left: -95px;
    margin-top: 13px;
  }

  .c30 {
    margin-left: 49px;
    margin-top: 41px;
  }

  .c31 {
    margin-left: -79px;
    margin-top: -71px;
  }

  .c32 {
    margin-left: -111px;
    margin-top: -15px;
  }

  .c33 {
    margin-left: 65px;
    margin-top: -43px;
  }

  .c34 {
    margin-left: 65px;
    margin-top: 13px;
  }

  .c35 {
    margin-left: -79px;
    margin-top: 41px;
  }

  .c36 {
    margin-left: 49px;
    margin-top: -71px;
  }

  .c37 {
    margin-left: 81px;
    margin-top: -15px;
  }

  .r1 {
    animation-name: pulse00;
    animation-duration: 2s;
    animation-iteration-count: infinite;
    animation-delay: 0.2s;
    -webkit-animation-name: pulse00;
    -webkit-animation-duration: 2s;
    -webkit-animation-iteration-count: infinite;
    -webkit-animation-delay: 0.2s;
  }

  .r2 {
    animation-name: pulse00;
    animation-duration: 2s;
    animation-iteration-count: infinite;
    animation-delay: 0.4s;
    -webkit-animation-name: pulse00;
    -webkit-animation-duration: 2s;
    -webkit-animation-iteration-count: infinite;
    -webkit-animation-delay: 0.4s;
  }

  .r3 {
    animation-name: pulse00;
    animation-duration: 2s;
    animation-iteration-count: infinite;
    animation-delay: 0.6s;
    -webkit-animation-name: pulse00;
    -webkit-animation-duration: 2s;
    -webkit-animation-iteration-count: infinite;
    -webkit-animation-delay: 0.6s;
  }

  .r1 > .hex-brick {
    animation-name: fade00;
    animation-duration: 2s;
    animation-iteration-count: infinite;
    animation-delay: 0.2s;
    -webkit-animation-name: fade00;
    -webkit-animation-duration: 2s;
    -webkit-animation-iteration-count: infinite;
    -webkit-animation-delay: 0.2s;
  }

  .r2 > .hex-brick {
    animation-name: fade00;
    animation-duration: 2s;
    animation-iteration-count: infinite;
    animation-delay: 0.4s;
    -webkit-animation-name: fade00;
    -webkit-animation-duration: 2s;
    -webkit-animation-iteration-count: infinite;
    -webkit-animation-delay: 0.4s;
  }

  .r3 > .hex-brick {
    animation-name: fade00;
    animation-duration: 2s;
    animation-iteration-count: infinite;
    animation-delay: 0.6s;
    -webkit-animation-name: fade00;
    -webkit-animation-duration: 2s;
    -webkit-animation-iteration-count: infinite;
    -webkit-animation-delay: 0.6s;
  }

  @keyframes pulse00 {
    0% {
      -webkit-transform: scale(1);
      transform: scale(1);
    }
    50% {
      -webkit-transform: scale(0.01);
      transform: scale(0.01);
    }
    100% {
      -webkit-transform: scale(1);
      transform: scale(1);
    }
  }

  @keyframes fade00 {
    0% {
      background: #252525;
    }
    50% {
      background: #000000;
    }
    100% {
      background: #353535;
    }
  }
}
