@import "../../../theme/themeVariables.scss";

.parametersContainer {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: flex-end;
  height: 100%;
  max-width: 600px;
}

.parameterWindow {
  margin: 10px;
  background-color: $superLight;
  border: 1px solid black;
  border-radius: 10px;
  position: relative;

  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(128, 128, 128, 0.5);
    z-index: 50;
  }

  .modelNameAndButtons {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 3px;
    padding: 7px 7px 0px 7px;
    background-color: $secondary;
    border-radius: 10px 10px 0 0;

    &.mini {
      min-width: none;
    }

    .sizeIcon {
      display: none;
      cursor: pointer;
      width: 20px;
      height: 20px;
    }

    .modelName {
      font-size: 0.9em;
      font-weight: 600;
      margin-right: 3px;
      padding-bottom: 5px;
      max-width: 135px;
      word-break: break-all;
      text-align: start;
      cursor: pointer;
    }

    .buttons {
      display: none;
      align-items: center;
      justify-content: center;

      .button {
        .icon {
          height: 22px;
          width: 22px;
        }
      }
    }
  }

  .buttonsContainer {
    .settingsIcon {
      display: none;
    }
  }

  .placeholder {
    display: block;
    width: 20px;
  }
  .modelNameAndButtons:focus,
  .modelNameAndButtons:hover {
    .sizeIcon {
      display: block;
    }

    .placeholder {
      display: none;
    }

    .buttonsContainer {
      user-select: none;
      position: relative;

      .settingsIcon {
        display: block;
        height: 20px;
        margin-top: -5px;
        cursor: pointer;
      }

      &:hover {
        .buttons {
          background-color: white;
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          justify-content: flex-start;
          position: absolute;
          left: 0;
          top: 0;
          height: fit-content;
          width: 80px;
          margin-top: 10px;
          padding: 5px;
          border-radius: 3px;
          z-index: 9999;

          .iconsRow {
            display: flex;

            .withIcon:last-child {
              margin-left: 10px;
            }
          }

          .button {
            width: 100%;
            text-align: left;
            cursor: pointer;
            &:hover {
              background-color: $superLight;
            }
          }
        }
      }
    }

    // .buttons {
    //   display: flex;

    //   .button {
    //     display: flex;
    //     cursor: pointer;
    //     border: 1px solid black;
    //     font-size: 0.9em;
    //     border-radius: 8px;
    //     margin-right: 3px;
    //     padding-left: 4px;
    //     padding-right: 4px;
    //     min-width: 25px;
    //     text-align: center;

    //     &.withIcon {
    //       border: 0;
    //       width: 20px;
    //       padding: 0;
    //       margin: 0;
    //     }
    //   }
    // }
  }

  // .divider {
  //   width: calc(100% - 14px);
  //   margin-left: 7px;
  //   margin-top: 0;
  // }

  .tablesArea {
    padding: 7px;

    .nonRecuringParams {
      .checkboxAndDropdown {
        display: flex;
        flex-wrap: wrap;
      }

      .subModels {
        text-align: left;

        .nonRecSubModel {
          margin-bottom: 10px;
          table {
            border-spacing: 0;
            border-left: 1px solid black;

            th {
              margin: 0;
              border-top: 1px solid black;
              padding-right: 3px;
              padding-left: 3px;
              width: auto;
              border-bottom: 1px solid black;
              border-right: 1px solid black;
              text-align: center;
            }

            tr {
              .tableSpacerContainer {
                display: flex;
                border-right: 0;
                border-bottom: 0;
              }
            }

            tr:hover {
              .tableSpacerContainer {
                display: none;
              }
            }

            td {
              margin: 0;
              padding: 0;
              width: auto;
              border-bottom: 1px solid black;
              border-right: 1px solid black;
              text-align: center;

              input {
                font-size: 1rem;
                padding: 3px;
                margin: 0;
                border: 0;
                height: 100%;
                width: auto;
                text-align: center;
              }

              .spacer {
                width: 17px;
                height: 20px;
              }
            }
          }
        }
      }
    }
  }
}

.recuringParams {
  margin-top: 0px;
  margin-bottom: 7px;
  display: flex;
}

.vdfCheckboxes {
  font-size: 0.8em;

  .title {
    margin-top: calc(10px + 0.5vh);
    margin-bottom: 0px;
    font-size: 0.9rem;
    font-weight: 600;
    text-align: left;
    color: inherit;
  }

  .checkboxContainer {
    display: flex;
    flex-direction: row;
    user-select: none;
    margin-top: -10px;
    padding-top: 0;
  }
}

.lineshapeSection {
  font-size: 0.8em;
  margin-bottom: 7px;

  .title {
    margin-top: calc(10px + 0.5vh);
    margin-bottom: 10px;
    font-size: 0.9rem;
    font-weight: 600;
    text-align: left;
    color: inherit;
  }

  .selectedLineshapes {
    margin-bottom: 10px;
    
    .selectedLineshape {
      border-radius: 0px;
      margin-bottom: 5px;
      display: flex;
      font-size: 0.9rem;
      
      .title {
        font-size: 0.9rem;
        font-weight: 600;
        text-align: left;
        color: inherit;
      }
    }
  }

  .lineshapeButtonContainer {
    display: flex;
    justify-content: flex-start;
    margin-bottom: 5px;
    
    .lineshapeButton {
      font-size: 0.9rem;
    }
  }
}

.lineshapeList {
  max-height: 300px;
  overflow-y: auto;
  
  .lineshapeItem {
    padding: 8px 8px;
    cursor: pointer;
    font-size: 1.1em;
    margin-top: 0.5vh;
    margin-bottom: 0.5vh;
    border-bottom: 1px solid #79797979;
    border-radius: 0;
    
    &:hover {
      color: #1976d2;
      text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.3);
      background-color: transparent;
    }
    
    &:last-child {
      border-bottom: 0px solid #797979a4;
    }
  }
  
  .noShapesMessage {
    padding: 10px;
    font-style: italic;
    color: #666;
  }
}

.addAnotherLine {
  display: flex;
  height: 100%;
}

#buttonCell {
  display: none;
  border-right: 0px solid black;
  border-bottom: 0px solid black;
  width: 51px;

  .addContainer {
    position: relative;
    width: 51px;

    .addButton {
      position: absolute;
      left: 0;
      width: 18px;
      margin-left: 2px;
      cursor: pointer;
    }
    .duplicateButton {
      position: absolute;
      left: 18px;
      width: 18px;
      margin-left: 2px;
      cursor: pointer;
    }
  }

  .deleteButton {
    width: 15px;
    margin-left: 39px;
    cursor: pointer;
  }
}

tr:hover #buttonCell {
  // display: table-cell;
  display: flex;
  align-items: flex-end;
}

.paramsTable {
  display: flex;
  align-items: flex-end;
  .tableContainer {
    .tableTitle {
      margin-top: calc(10px + 0.5vh);
      margin-bottom: 0px;
      font-size: 0.9rem;
      font-weight: 600;
      text-align: left;
      color: inherit;
    }
    &.noTitle {
      margin-top: calc(10px + 0.5vh);
    }
    table {
      border-spacing: 0;
      border-left: 1px solid black;

      th {
        margin: 0;
        border-top: 1px solid black;
        padding-right: 3px;
        padding-left: 3px;
        width: auto;
        border-bottom: 1px solid black;
        border-right: 1px solid black;
        text-align: center;
      }

      tr {
        .tableSpacerContainer {
          display: flex;
          border-right: 0;
          border-bottom: 0;
        }
      }

      tr:hover {
        .tableSpacerContainer {
          display: none;
        }
      }

      td {
        margin: 0;
        padding: 0;
        width: auto;
        border-bottom: 1px solid black;
        border-right: 1px solid black;
        text-align: center;

        input {
          font-size: 1rem;
          padding: 3px;
          margin: 0;
          border: 0;
          height: 100%;
          width: auto;
          text-align: center;
        }

        .spacer {
          width: 17px;
          height: 20px;
        }
      }
    }
  }
}

.nonRecParamsTables {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  .tableContainer {
    .tableTitle {
      margin-top: calc(10px + 0.5vh);
      margin-bottom: 0px;
      font-size: 0.9rem;
      font-weight: 600;
      text-align: left;
      color: inherit;
    }
    &.noTitle {
      margin-top: calc(10px + 0.5vh);
    }

    table {
      border-spacing: 0;
      border-left: 1px solid black;
      margin-top: 0;

      th {
        margin: 0;
        border-top: 1px solid black;
        padding-right: 3px;
        padding-left: 3px;
        width: auto;
        border-bottom: 1px solid black;
        border-right: 1px solid black;
        text-align: center;
      }

      td {
        margin: 0;
        padding: 0;
        width: auto;
        border-bottom: 1px solid black;
        border-right: 1px solid black;
        text-align: center;

        input {
          font-size: 1rem;
          padding: 3px;
          margin: 0;
          border: 0;
          height: 100%;
          width: auto;
          text-align: center;
        }
      }
    }
  }
  .tableContainer:first-child {
    .tableTitle {
      margin-top: 0px;
    }
    &.noTitle {
      margin-top: 0px;
    }
  }
}

.cellForTable {
  input {
    background-color: transparent;
    color: inherit;
    font-weight: inherit;
  }

  .modelCell {
    cursor: pointer;
    user-select: none;
  }
}

.stickyWindow {
  height: 300px;
  width: 320px;
  position: absolute;
  border: 1px solid black;
  background-color: white;
  border-radius: 10px 10px 0 0;
  box-shadow: 6px 5px 10px rgba(0, 0, 0, 0.096);
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-start;
  text-align: left;
  align-content: space-between;
  justify-content: center;

  &.fitOpen {
    margin-right: 470px;
  }

  .dragHandle {
    height: 30px;
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding-right: 10px;
    border-radius: 10px 10px 0 0;
    background-color: $secondary;
    cursor: grab;

    .dragIcon {
      height: 20px;
    }
  }

  .content {
    width: 100%;
    height: calc(100% - 35px);
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-items: flex-start;
    text-align: left;
    align-content: space-between;
    justify-content: center;
    padding: 15px;
    padding-top: 0;
    padding-bottom: 0;

    .allValueEdits {
      width: 180px;
      height: 100%;
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      justify-content: space-around;
      user-select: none;
    }

    .valueContainer {
      display: flex;
    }

    .hardMaxContainer {
      display: flex;
      margin-top: 5px;
    }

    .hardMinContainer {
      margin-top: 5px;
      display: flex;
    }

    .groupDropDown {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: flex-end;

      .dropdown {
        width: 120px;
      }

      .clearGroup {
        cursor: pointer;
        text-decoration: underline;
      }

      .clearGroup:hover {
        color: $complimentary;
      }
    }

    .sliderContainer {
      width: 95px;
      height: calc(100% - 35px);
      display: flex;
      justify-content: center;
      position: relative;
    }
  }
}

.modal-content {
  position: absolute;
  width: 90%;
  height: 90%;
  transition: transform 0.5s ease-in-out;
  z-index: 110;
}

.slide-in-right {
  transform: translateX(0);
}

.slide-out-left {
  transform: translateX(-110%);
}

.backSection {
  display: flex;
  cursor: pointer;

  .backIcon {
    width: 18px;
  }
}

.fileSettingContainer {
  height: 98%;
  width: 100%;
  background-color: white;
  display: flex;
  flex-direction: column;

  $inputHeight: 30px;
  $leftmargin: 0;

  position: relative;

  .confirmButtonSection {
    position: absolute;
    bottom: 0;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;

    .warningNote {
      font-size: 0.9em;
      // color: gray;
      // font-style: italic;
      text-align: center;
    }

    .confirmButton {
      background-color: $primary;
      padding: 5px 25px 5px 25px;
      border-radius: 10px;
    }

    .confirmButton:hover {
      background-color: $complimentary;
    }
  }

  .fileName {
    margin-bottom: 10px;
  }

  .unitsContainer {
    display: flex;

    .x_unit_select {
      height: $inputHeight;
      width: 100px;
      padding-top: 0;
      margin-left: 10px;
    }
  }

  .autoCutting {
    margin-top: 5px;

    .note {
      margin-bottom: 5px;
      font-size: 0.8em;
      font-style: italic;
      color: grey;
    }
  }

  .allCutsContainer {
    position: relative;
    height: 160px;
    overflow: auto;

    .cutContainer {
      display: flex;
      align-items: center;

      .deleteCut {
        height: 16px;
        margin-bottom: -5px;
        cursor: pointer;
        display: none;
      }
    }

    .cutContainer:hover {
      .deleteCut {
        display: inline;
      }
    }

    .addCut {
      position: absolute;
      height: 18px;
      right: 70px;
    }
  }

  .rangeContainer {
    margin-left: $leftmargin;
    margin-top: 5px;
    display: inline;
    width: 80%;

    .rangeValContainer {
      margin-top: 3px;
      display: flex;
      justify-content: space-evenly;
      margin-right: 0;
    }

    .rangeVal {
      height: $inputHeight;
      width: 70px;
    }

    input[type="number"]::-webkit-inner-spin-button,
    input[type="number"]::-webkit-outer-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    input[type="number"] {
      appearance: textfield;
      -moz-appearance: textfield;
    }
  }
}

.temperature {
  margin-bottom: 10px;

  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  input[type="number"] {
    appearance: textfield;
    -moz-appearance: textfield;
  }

  .tempDropdown {
    margin-left: 15px;
  }
}

.customTooltip {
  background-color: rgba(0, 0, 0, 0.849);
  padding: 4px 8px 4px 8px;
  width: max-content;
  border-radius: 7px;
  color: white;
  margin-bottom: -15px;
  font-size: 1.2em;

  .tooltipContent {
    text-align: center;
  }

  &.clickable {
    cursor: pointer;
  }
}

.minMaxConfirmModalContent {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  height: 100%;
  width: 100%;

  .minMaxConfirmModalButtons {
    display: flex;
    justify-content: space-evenly;
    width: 100%;
  }
}

.vdfModal {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  width: 100%;
  user-select: none;

  .modalHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 0px;
    padding-bottom: 0px;
    //border-bottom: 1px solid $secondary;

    .headerLeft {
      display: flex;
      align-items: center;
      flex: 1;
    }

    .title {
      font-weight: 600;
      font-size: 1.1em;
      margin-right: 10px;
    }

    .createVDFButton {
      font-size: 0.8em;
      padding: 2px 8px;
      min-width: 80px;
      height: 26px;
    }

    .deleteGraphButton {
      display: flex;
      background-color: rgb(233, 2, 2);
      border-radius: 6px;
      border: 1px solid rgb(182, 182, 182);
      cursor: pointer;

      .deleteGraphIcon {
        color: white;
        height: 22px;
        margin: 0 7px;
      }
    }
  }

  .createArea {
    flex: 0.5;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    position: relative;

    .buttonGroup {
      display: flex;
      gap: 10px;
      justify-content: center;
    }

    .createVDFButton {
      font-size: 0.9em;
      padding: 3px 12px;
      height: 30px;
      
      &:disabled {
        opacity: 0.7;
        cursor: not-allowed;
      }
    }

    .removeVDFButton {
      font-size: 0.9em;
      padding: 3px 12px;
      height: 30px;
    }

    .loadVdfIcon {
      position: absolute;
      top: 0;
      right: 0;
      height: 20px;
      width: 20px;
      cursor: pointer;

      &:hover {
        scale: 105%;
      }
    }
  }

  .optionsArea {
    flex: 5;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;
    padding: 0 10px 10px 10px;
    overflow: auto;
    margin-left: 0px;

    .noGridMessage {
      font-size: 1.1em;
      font-style: italic;
      color: #666;
      text-align: left;
      margin: 0px 0;
    }

    .enodesSection {
      margin-bottom: 0px;
      
      .sectionTitle {
        font-size: 1.1em;
        font-weight: 600;
        margin-bottom: 0px;
        margin-top: 5px;
      }
      
      .enodesInfo {
        //background-color: $secondary;
        border-radius: 5px;
        padding: 0px;
        margin-top: 5px;
        
        .enodesDetail {
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-bottom: 5px;
          
          .label {
            font-weight: 600;
            margin-right: 5px;
          }
          
          .value {
            color: #333;
          }
        }
      }
    }

    .checkboxes {
      flex: 2;
    }

    .gridButtonArea {
      flex: 1.5;
    }

    .gridDefineArea {
      display: flex;
      flex-direction: column;
      width: 100%;

      .gridTitle {
        font-size: 1.1em;
        font-weight: 600;
        margin-bottom: 5px;
        margin-top: 5px;
        padding-top: 5px;
        border-top: 1px solid $secondary;
        margin-left: -10px;
      }

      .listHeader {
        display: flex;
        margin-bottom: 5px;
        font-weight: 600;

        .name {
          flex: 5;
        }

        .reduction {
          flex: 4;
          margin-left: 22px;
          padding-left: 20px;
        }

        .del {
          flex: 0.8;
        }
      }

      .selectedFileList {
        display: flex;
        flex-direction: column;
        max-height: 225px;
        overflow: auto;

        .vdfFileEntry {
          display: flex;
          align-items: center;
          justify-content: space-between;

          &:hover {
            background-color: $secondary;

            .deleteArea {
              .deleteButton {
                display: block;
                height: 15px;
                width: 15px;
              }
            }
          }

          .fileName {
            flex: 7;
          }

          .reductionFactor {
            flex: 2;

            input {
              width: 50px;
            }
          }

          .deleteArea {
            flex: 0.5;
            display: flex;
            justify-content: flex-start;
            margin-left: -8px;

            .deleteButton {
              display: none;
            }
          }
        }
      }
      .linearFilesSection {
        .linearEntry {
          display: flex;
          align-items: center;
          justify-content: space-between;

          &:hover {
            background-color: $secondary;

            .deleteArea {
              .deleteButton {
                display: block;
                height: 15px;
                width: 15px;
              }
            }
          }
          &:last-child {
            margin-bottom: 5px;
          }
          .details {
            display: flex;
            flex: 8;

            .number {
              margin-left: 10px;
            }
          }

          .reductionFactor {
            flex: 2;
            visibility: hidden;
          }

          .deleteArea {
            flex: 0.5;
            display: flex;
            justify-content: flex-start;
            margin-left: -8px;

            .deleteButton {
              display: none;
            }
          }
        }
      }

      .addFileButtonArea {
        margin-top: 10px;
        display: flex;

        .addFileButton {
          padding: 5px;
          cursor: pointer;
          display: flex;
          align-items: center;
          align-content: center;
          color: green;

          &:hover {
            scale: 101%;
            text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.233);
          }

          .addText {
            margin-left: 5px;
            font-size: 1.1em;
          }
        }
      }
    }
  }
}

.gridDefineModal {
  display: flex;
  flex-direction: column;

  .listHeader {
    display: flex;
    margin-bottom: 5px;
    font-weight: 600;

    .name {
      flex: 5;
    }

    .reduction {
      flex: 4;
    }

    .del {
      flex: 0.8;
    }
  }

  .selectedFileList {
    display: flex;
    flex-direction: column;
    max-height: 100px;
    overflow: auto;

    .vdfFileEntry {
      display: flex;
      align-items: center;
      justify-content: space-between;

      &:hover {
        background-color: $secondary;

        .deleteArea {
          .deleteButton {
            display: block;
            height: 15px;
            width: 15px;
          }
        }
      }

      .fileName {
        flex: 7;
      }

      .reductionFactor {
        flex: 2;

        input {
          width: 50px;
        }
      }

      .deleteArea {
        flex: 0.5;
        display: flex;
        justify-content: flex-start;
        margin-left: -8px;

        .deleteButton {
          display: none;
        }
      }
    }

    .linearEntry {
      display: flex;
      align-items: center;
      justify-content: space-between;

      &:hover {
        background-color: $secondary;

        .deleteArea {
          .deleteButton {
            display: block;
            height: 15px;
            width: 15px;
          }
        }
      }

      .details {
        display: flex;

        .number {
          margin-left: 10px;
        }
      }

      .deleteArea {
        flex: 0.5;
        display: flex;
        justify-content: flex-start;
        margin-left: -8px;

        .deleteButton {
          display: none;
        }
      }
    }
  }

  .addFileButtonArea {
    margin-top: 10px;
    display: flex;

    .addFileButton {
      padding: 5px;
      cursor: pointer;
      display: flex;
      align-items: center;
      align-content: center;
      color: green;

      &:hover {
        scale: 101%;
        text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.233);
      }

      .addText {
        margin-left: 5px;
        font-size: 1.1em;
      }
    }
  }
}

.selectFilesModal {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
  position: relative;

  .modalHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
    padding-bottom: 5px;
    //border-bottom: 1px solid $secondary;

    .title {
      font-weight: 600;
      font-size: 1.1em;
    }

    .closeVdfModalButtonContainer{
      position: absolute;
      top: 0;
      right: 0;
      margin-top: -10px;
      margin-right: -10px;
    }

    // .deleteGraphButton {
    //   display: flex;
    //   background-color: rgb(233, 2, 2);
    //   border-radius: 6px;
    //   border: 1px solid rgb(182, 182, 182);
    //   cursor: pointer;

    //   .deleteGraphIcon {
    //     color: white;
    //     height: 22px;
    //     margin-left: 7px;
    //     margin-right: 7px;
    //   }
    // }
  }

  .filesSection {
    flex-grow: 1;
    overflow-y: auto;
    margin-bottom: 10px;
  }

  .selectableFileEntry {
    user-select: none;
    cursor: pointer;

    &:hover {
      background-color: $secondary;
    }
  }

  .linearEntry {
    display: flex;
    flex-direction: column;
    margin-top: 5px;
    border-top: 1px solid black;
    padding-top: 5px;

    .title {
      font-size: 1.1em;
      font-weight: 600;
    }

    .entryFields {
      display: flex;
      justify-content: space-between;
      align-items: center;
      
      .entry {
        width: 100px;
      }
    }

    .buttonArea {
      margin-top: 15px;
      
      .createVDFButton {
        font-size: 0.9em;
        padding: 3px 12px;
        height: 30px;
      }
    }
  }
}

.modelExchange {
  display: flex;
  flex-direction: column;
  user-select: none;
  overflow: auto;

  .option {
    cursor: pointer;
    display: flex;

    &:hover {
      background-color: $secondary;
    }

    &.new {
      margin-top: 5px;
      color: green;
    }
  }
}

.viewGridModal {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  user-select: none;

  .modalHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
    padding-bottom: 5px;
    //border-bottom: 1px solid $secondary;

    .title {
      font-weight: 600;
      font-size: 1.1em;
    }

    .deleteGraphButton {
      display: flex;
      background-color: rgb(233, 2, 2);
      border-radius: 6px;
      border: 1px solid rgb(182, 182, 182);
      cursor: pointer;

      .deleteGraphIcon {
        color: white;
        height: 22px;
        margin: 0 7px;
      }
    }
  }

  .gridPointsContainer {
    flex: 1;
    overflow-y: auto;
    padding: 0 5px;

    .gridPoint {
      display: flex;
      padding: 3px 0;
      //border-bottom: 1px solid #eee;


      .index {
        width: 55px;
        font-weight: 600;
        color: #555;
      }

      .value {
        flex: 1;
      }
    }
  }
}

// Add styles for modalList in the parameters context to match the Models.js styling
.modalList {
  .modalListEntry {
    border-bottom: 1px solid #79797979;

    &:last-child {
      border-bottom: 0px solid #797979a4;
    }
  }

  .modalListEntryName {
    cursor: pointer;
    display: inline;
    font-size: 1.1em;
    margin-top: 0.5vh;
    margin-bottom: 0.5vh;

    &:hover {
      color: $primary;
      text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.3);
    }
  }

  .noShapesMessage {
    padding: 10px;
    font-style: italic;
    color: #666;
  }
}
